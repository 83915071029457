import {Step, StepConnector, Stepper as MuiStepper, stepConnectorClasses, StepIconProps, StepLabel, styled} from "@mui/material";

const StepperConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 20,
    },

    [`& .${stepConnectorClasses.line}`]: {
        height: 2,
        border: 0,
        margin: "0 auto",
        width: "95%",
        backgroundColor: theme.palette.grey[400]
    },
}));

const StepperIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    fontSize: "24px",
    zIndex: 1,
    color: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: theme.palette.secondary.main,
    }),
    ...(ownerState.completed && {
        backgroundColor: theme.palette.secondary.main,
    }),
}));

function StepperIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: string } = {
        1: "1",
        2: "2",
        3: "3",
    };

    return (
        <StepperIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </StepperIconRoot>
    );
}

type StepperProps = {
    step: number;
    labels: string[];
}

const Stepper = ({step, labels}: StepperProps) => {
    return <MuiStepper activeStep={step} alternativeLabel connector={<StepperConnector/>} sx={{width: {xs:"90%", md:"70%"}, mt: "48px"}}>
        <Step key={0}>
            <StepLabel StepIconComponent={StepperIcon}>{labels[0]}</StepLabel>
        </Step>
        <Step key={1}>
            <StepLabel StepIconComponent={StepperIcon}>{labels[1]}</StepLabel>
        </Step>
        <Step key={2}>
            <StepLabel StepIconComponent={StepperIcon}>{labels[2]}</StepLabel>
        </Step>
    </MuiStepper>
}

export default Stepper;