import {Grid, Box, IconButton, Typography, useTheme} from "@mui/material";
import DownloadIcon from "../ui/icons/DownloadIcon";

type Props = {
    content: {
        title: string,
        img: {
            alt: string,
            url: string,
        },
        file: {
          url: string,
        },
        description: string,
        tag: string,
    };
}

const GuidesTile = ({content}: Props) => {
    const {palette} = useTheme();

    return (
        <Grid container justifyContent="center" alignItems="center" pt={4}>
            <Grid container item flexDirection={{xs: 'column', lg: 'row'}} justifyContent="space-between" alignItems="center" sx={{backgroundColor: '#F4F4F4', width: {xs: "90%", sm: '60%', lg: "1175px"}}}>
                <Grid item>
                    <Box component="img"
                         sx={{
                             width: {xs: '100%', lg: '300px'},
                             height: {xs: '100%', lg: '250px'},
                             objectFit: 'cover',
                             marginBottom: '-8px'
                         }}
                         alt={content.img.alt}
                         src={content.img.url}
                    />
                </Grid>
                <Grid item xs={10} lg={7} sx={{display: 'flex', height: '250px', width: {xs: '100%', lg: 'auto'}}} flexDirection="column" justifyContent={{xs: 'start', lg: 'space-between'}} alignItems="stretch">
                    <Grid item pt={3}>
                        <Typography variant="h5">{content.title}</Typography>
                    </Grid>
                    <Grid item pb={5}>
                        <Typography variant="body1" sx={{xs: '100%', lg: 'auto'}}>{content.description}</Typography>
                    </Grid>
                    <Grid item container pb={3}>
                        <Box sx={{textTransform: 'uppercase', textAlign: 'center', maxWidth: '150px', backgroundColor: palette.secondary.main, color: 'white', p: '3px 20px 0 20px', borderRadius: '15px'}}>{content.tag}</Box>
                    </Grid>
                </Grid>
                <Grid item xs={10} lg={1} pb={1} sx={{textAlign: 'center', alignSelf: {xs: 'center',lg: 'flex-end'}}} pr={{xs: 0, lg: 3}}>
                    <IconButton href={content.file.url} target={"_blank"} sx={{'&:hover': {cursor: 'pointer'}, display: 'flex', flexDirection: 'column'}}>
                        <DownloadIcon />
                        <Typography mt={1} variant="body1" color="primary.main">Pobierz</Typography>
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default GuidesTile;
