import {Button, Grid, styled} from "@mui/material";
import SectionTitle from "../../ui/SectionTitle";

type Props = {
    other: any[];
}

const WhiteButton = styled(Button)(({theme}) => ({
    padding: "20px 48px",
    borderRadius: "100px",
    border: "2px solid",
    fontWeight: 700,
    fontSize: "18px",
    textTransform: "none",
    whiteSpace: "nowrap",
    backgroundColor: "white",
    color: theme.palette.secondary.main,
    "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        opacity: 0.7,
        color: "white",
    }
}))

const OtherPayment = ({other}: Props) => {
    return <Grid item container justifyContent={"center"} sx={{minWidth: "100%", m: "0", backgroundColor: "white", position: "relative", mb: "96px"}}>
        <SectionTitle title={"Można nas również wesprzeć poprzez portale:"}/>
        <Grid item container justifyContent={"center"}>
            {other.map(item => (
                <Grid item flexDirection={"column"} alignItems={"center"} sx={{display: "flex", m: "0 36px"}}>
                    <Grid item sx={{
                        height: "176px",
                        width: "255px",
                        background: `url(${item.logo.sizes.medium})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }} />
                    <WhiteButton onClick={() => window.open(item.link, "_blank")}>Pomagam</WhiteButton>
                </Grid>
            ))}
        </Grid>
    </Grid>
    }

export default OtherPayment;