import {useEffect, useState} from "react";
import {Box, Grid, styled, useTheme} from "@mui/material";
import SectionTitle from "../../ui/SectionTitle";
import small_bubble_right from "../../assets/images/small-light-bubble.png";
import small_bubble_left from "../../assets/images/small-dark-half-bubble.png";
import Stepper from "../../ui/Stepper";
import PersonalData from "./PersonalData";
import {CvFormContextProvider} from "./cv-form-context";
import AdditionalQuestions from "./AdditionalQuestions";
import {StyledTextField} from "../../ui/StyledTextfield";
import CvConfirmation from "./CvConfirmation";
import {useLocation} from "react-router-dom";

export const StyledField = styled(StyledTextField)({
    '& .MuiInputBase-input': {
        paddingLeft: "40px",
    },
})

type BubbleProps = {
    url: string;
    top: any;
    horizontal: any;
}

const Bubble = ({url, top, horizontal}: BubbleProps) => {
    return <Box sx={{position: "absolute", top: top, left: horizontal.left, right: horizontal.right}}>
        <img src={url} alt={"bubble_background"}/>
    </Box>
}


const CvForm = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const {palette} = useTheme();
    const location = useLocation();

    useEffect(() => {
       if(location.hash === '#formularz') {
           console.log(location);
           const element = document.getElementById('formularz');
           if(element) {console.log(element); element.scrollIntoView({behavior: 'smooth'})}
       }
    }, [location])

    return <Grid item container justifyContent={"center"} flexDirection={"column"} alignItems={"center"} sx={{width: "100%", overflow: "hidden", position: "relative", pb: "96px"}} id={"formularz"}>
        <SectionTitle title={"Formularz"}/>
        <Bubble url={small_bubble_left} top={"10%"} horizontal={{left: 0}}/>
        <Bubble url={small_bubble_right} top={"75%"} horizontal={{right: "-70px"}}/>
        <Grid container justifyContent={"center"} flexDirection={"column"} alignItems={"center"} sx={{width: {lg: "808px", xs: "90%"}, m: "0 auto", backgroundColor: palette.grey[100], position: "relative"}}>
            <Stepper step={tabIndex} labels={["Dane osobowe", "Pytania dodatkowe", "Potwierdzenie"]}/>
            <CvFormContextProvider>
                {[<PersonalData onNext={() => setTabIndex(1)}/>,
                    <AdditionalQuestions onNext={() => setTabIndex(2)} onBack={() => setTabIndex(0)}/>,
                    <CvConfirmation />
                ][tabIndex]}
            </CvFormContextProvider>
        </Grid>
    </Grid>
}

export default CvForm;
