import {Typography, useTheme} from "@mui/material";

type Props = {
    title: string;
    margin?: boolean;
}

const SectionTitle = ({title, margin}: Props) => {
    const {palette} = useTheme();

    return <Typography variant={"h5"} mt={`${!margin ? "96px" : '0px'}`} mb={`${!margin ? "48px" : '0px'}`} color={palette.secondary.main} textAlign={"center"} sx={{width: '100%', zIndex: 10, fontWeight: 700}}>
        {title}
    </Typography>

}

export default SectionTitle;
