import {Box, Grid, Typography, useTheme} from "@mui/material";

type Props = {
    content: any;
}

const ImageWithTitle = ({content}: Props) => {
    const {palette} = useTheme();

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" xs={12} pt={12} pb={12}>
            <Typography variant='h5' color={palette.secondary.main} sx={{textAlign: {xs: 'center', lg: 'auto'}}}>{content.title}</Typography>
            <Box component='img' sx={{
                objectFit: 'cover',
                width: {xs:  '100%', xl: '1152px'},
                maxWidth: '1152px'
            }}
                 alt={content.img[0].img.alt}
                 src={content.img[0].img.url}
            />
        </Grid>
    )
}

export default ImageWithTitle;
