import {Grid, Box, Typography, useTheme} from "@mui/material";
import {useContext} from "react";
import ContentContext from "../store/content-context";
import GreenPhoneIcon from "../ui/icons/GreenPhoneIcon";
import SmallGreenPhoneIcon from "../ui/icons/SmallGreenPhoneIcon";
import LocalizationIcon from "../ui/icons/LocalizationIcon";
import GreenMailIcon from "../ui/icons/GreenMailIcon";
import ContactForm from "../components/ContactForm";

type Props = {
    content: any;
}

const ContactDetails = ({content}: Props) => {
    const {palette} = useTheme();
    const {foundation} = useContext(ContentContext);

    return <Grid container item justifyContent={'center'} alignItems={"center"} pt={12}>
       <Grid xs={12} md={10} xl={8} container item sx={{
           display: 'grid',
           gridTemplateColumns: {xs: '1fr', md: '1fr 1fr', lg: 'repeat(3, 1fr)'},
           gridTemplateRows: {xs: 'repeat(9, 1fr)', md: 'repeat(5, 1fr)', lg: 'repeat(3, 1fr)'},
           justifyItems: {xs: 'center', md: 'start'},
           alignItems: 'center',
           gap: '60px 10px'
       }} pb={12}>
           <Grid item>
               <Box sx={{textAlign: {xs: 'center', md: 'left'}}}>
                   <LocalizationIcon />
               </Box>
               <Typography variant="h6" sx={{fontWeight: 400, textAlign: {xs: 'center', md: 'left'}}}>{foundation.streetAndNumber}</Typography>
               <Box sx={{display: 'flex'}}>
                   <Typography variant="h6" sx={{fontWeight: 400}} mt={-1}>{foundation.postalCode}</Typography>
                   <Typography variant="h6" sx={{fontWeight: 400}} ml={1} mt={-1}>{foundation.city}</Typography>
               </Box>
           </Grid>
           <Grid item>
               <>
                   <Box sx={{textAlign: {xs: 'center', md: 'left'}}}>
                       <GreenPhoneIcon />
                   </Box>
                   {foundation.phones.map((phone, index: number) => {
                       return <Typography variant="h6" sx={{fontWeight: 400}} mt={index === 1 ? -1 : 0}>{phone}</Typography>
                   })}
               </>
           </Grid>
           <Grid item>
               <Box sx={{textAlign: {xs: 'center', md: 'left'}}}>
                   <GreenMailIcon />
               </Box>
               <Typography variant="h6" sx={{fontWeight: 400}}>{content.email}</Typography>
           </Grid>
           <Grid item>
               <Typography variant="h5" color="secondary.main">{content.title}</Typography>
           </Grid>
                {content.person.map((item: any) => {
                    return (
                        <Grid item>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <Typography sx={{fontSize: '17px !important', fontWeight: 400, textAlign: {xs: 'center', md: 'left'}}}>{item.job}</Typography>
                                <Typography mt={-1} sx={{fontSize: '24px !important', fontWeight: 700, textAlign: {xs: 'center', md: 'left'}}}>{item.name}</Typography>
                                <Box sx={{display: 'flex', justifyContent: {xs: 'center', md: 'flex-start'}}} mt={1}>
                                    <SmallGreenPhoneIcon />
                                    <Typography variant="h6" sx={{fontWeight: 400}} ml={1}>{item.phone}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    )
                })}
       </Grid>
    </Grid>
}

export default ContactDetails;
