import {Grid, Typography, Box} from "@mui/material";
import DOMPurify from "dompurify";

type Props = {
    content: any;
}

const TitleWithText = ({content}: Props) => {

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{position: 'relative', backgroundColor: `${content.bg ? '#F4F4F4' : '#fff'}`}}>
            <Grid container item justifyContent="center" alignItems="center" xs={11} lg={9} xl={8}>
                <Grid item pt={12} pb={6} sx={{textAlign: 'center'}}>
                    <Typography variant="h5" color="secondary.main">{content.title}</Typography>
                </Grid>
                {content.image ?
                    <Box component='img' sx={{
                        position: 'absolute',
                        top: {sm: '40px', lg: '220px'},
                        right: '0',
                        zIndex: 2,
                        display: {xs: 'none', lg: 'block'}
                    }}
                         alt='Fundacja Pomocy Dzieciom'
                         src={content.image.url}
                    /> : ''}
                <Grid item pb={12}>
                    <Box sx={{maxWidth: {xs: '100%', lg: '60%'}}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content.description)}} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TitleWithText;
