import {Grid, ToggleButton, Button, styled, useTheme, ToggleButtonGroup, TextField} from "@mui/material";
import {useContext, useState} from "react";
import PaymentContext from "./PaymentForm/payment-context";
import {useNavigate} from "react-router-dom";

const GridStyledItem = styled(Grid)(({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
        width: '90%',
    },
}));

const StyledToggleButton = styled(ToggleButton)({
    borderRadius: '4px !important',
    borderLeftColor: 'rgba(0, 0, 0, 0.12) !important'
});

const Form = () => {
    const theme = useTheme();
    const [payment, setPayment] = useState('once');
    const [amount, setAmount] = useState("50");
    const {setPayment: setCtxPayment, setPersonalDetails, setIndex} = useContext(PaymentContext);
    const navigate = useNavigate();
    const [data, setUserData] = useState({
        name: '',
        lastName: ''
    });

    const handlePaymentChange = (
        event: React.MouseEvent<HTMLElement>,
        newPayment: string | null,
    ) => {
        if(newPayment !== null) {
            setPayment(newPayment);
        }
    };

    const handleAmountChange = (
        event: React.MouseEvent<HTMLElement>,
        newAmount: string | null,
    ) => {
        if(newAmount !== null) {
            setAmount(newAmount);
        }
    };

    const [formValidation, setFormValidation] = useState({
        nameError: '',
        lastNameError: ''
    })

    const handleNameChange = (event: { target: { value: string; }; })=> {
        const value = event.target.value.trim();
        const reg = new RegExp('^[A-Za-z]+$');
        let name = reg.test(value) ? value : data.name;
        if (value.length < 2) {
            setFormValidation({
                ...formValidation,
                nameError: 'Wprowadź imię'
            })
        } else {
            setFormValidation({
                ...formValidation,
                nameError: ''
            })
        }
        setUserData((prevState) => {
            return {
                ...prevState,
                name: name
            }
        })
    }

    const lastNameChangeHandler = (event: { target: { value: string; }; }) => {
        const value = event.target.value.trim();
        const reg = new RegExp('^[A-Z-a-z]+$');
        let lastname = reg.test(value) ? value : data.lastName;
        if (value.length < 2) {
            setFormValidation({
                ...formValidation,
                lastNameError: 'Wprowadź nazwisko'
            })
        } else {
            setFormValidation({
                ...formValidation,
                lastNameError: ''
            })
        }
        setUserData((prevState) => {
            return {
                ...prevState,
                lastName: lastname
            }
        })
    }

    const handleGoNext = () => {
        setPersonalDetails({firstName: data.name ?? "", lastName: data.lastName ?? "", email: ""});
        setCtxPayment({amount: isNaN(Number(amount)) ? 50 : Number(amount), type: payment === 'once' ? 'single' : 'regular' })
        setIndex(2);
        navigate("/dzialaj-z-nami/jak-pomoc")
    }


    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{backgroundColor: 'white', borderRadius: '3%'}}>
            <GridStyledItem item mt={3}>
                <TextField value={data.name} helperText={formValidation.nameError} error={formValidation.nameError !== ''} onChange={handleNameChange} required label="Imię" variant="outlined" size="small" color="secondary" fullWidth />
            </GridStyledItem>
            <GridStyledItem item mt={3}>
                <TextField onChange={lastNameChangeHandler} value={data.lastName} helperText={formValidation.lastNameError} error={formValidation.lastNameError !== ''} required label="Nazwisko" variant="outlined" size="small" color="secondary" fullWidth />
            </GridStyledItem>
            <GridStyledItem item mt={3}>
                <ToggleButtonGroup
                    color="secondary"
                    value={payment}
                    exclusive
                    onChange={handlePaymentChange}
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        columnGap: '10px',
                    }}
                >
                    <StyledToggleButton value="once" size="small">Jednorazowo</StyledToggleButton>
                    <StyledToggleButton value="subscription" size="small">Miesięcznie</StyledToggleButton>
                </ToggleButtonGroup>
            </GridStyledItem>
            <GridStyledItem item mt={3}>
                <ToggleButtonGroup
                    color="secondary"
                    value={amount}
                    exclusive
                    onChange={handleAmountChange}
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gridTemplateRows: '1fr 1fr',
                        columnGap: '10px',
                        rowGap: '10px'
                    }}
                >
                    <StyledToggleButton value="50" size="small" sx={{borderRadius: '5px'}}>50 zł</StyledToggleButton>
                    <StyledToggleButton value="100" size="small">100 zł</StyledToggleButton>
                    <StyledToggleButton value="150" size="small">150 zł</StyledToggleButton>
                    <StyledToggleButton value="200" size="small">200 zł</StyledToggleButton>
                </ToggleButtonGroup>
            </GridStyledItem>
            <GridStyledItem item mt={3} mb={2}>
                <Button onClick={() => handleGoNext()} color="secondary" variant="contained" size="medium" fullWidth>Pomagam</Button>
            </GridStyledItem>
        </Grid>
    )
}

export default Form;
