import {Button, styled} from "@mui/material";

export const GreenButton = styled(Button)(({theme}) => ({
    padding: "15px 48px",
    borderRadius: "100px",
    overflow: "hidden",
    border: "2px solid",
    fontWeight: 700,
    fontSize: "18px",
    textTransform: "none",
    whiteSpace: "nowrap",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        opacity: 0.7,
        color: "white",
    }
}))