import BlankLayout from "../layout/BlankLayout";
import {Box, Divider, Grid, Typography, useTheme} from "@mui/material";
import Logo from "../ui/Logo";
import {GreenButton} from "../ui/GreenButton";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../store/auth-context";
import {useNavigate} from "react-router-dom";
import {getAccount} from "../api/content-api";
import Bubble from "../ui/Bubble";
import darkBubble from "../assets/images/medium-dark-half-right-bubble.png";
import smallLightBubble from "../assets/images/micro-light-bubble.png";
import largeLightBubble from "../assets/images/medium-light-bottom-half-bubble.png";

const AccountPage = () => {
    const {email, id, firstName, lastName, token, logout} = useContext(AuthContext);
    const [amount, setAmount] = useState(0);
    const [updateDttm, setUpdateDttm] = useState("");
    const [avatar, setAvatar] = useState("");
    const {palette} = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        if(id && token) {
            getAccount(id, token, () => logout())
                .then((data: any) => {
                    setUpdateDttm(data.data.update_dttm);
                    setAmount(data.data.amount);
                    setAvatar(data.data.avatar);
                })
        } else {
            logout();
            navigate("/login");
        }
    }, [])

    const handleLogout = () => {
        logout();
        navigate('/');
    }

    const toLocaleDateTime = (updateDttm: string | null) => {
        if(updateDttm) {
            const date = new Date(updateDttm);
            return date.toLocaleDateString();
        }
        return '---'
    }

    return <BlankLayout>
        <Grid container sx={{width: "100%", height: "100vh", position: "relative", overflow: "hidden"}} flexDirection={"column"} alignItems={"center"} flexWrap={"nowrap"}>
            <Grid item container justifyContent={"space-between"} sx={{width: {lg: "1175px", xs: "90%"}, mt: {xs: "24px", md: "48px"}, zIndex: 2}}>
                <Logo sx={{height: {xs: "50px", md: "100%"}}}/>
                <Grid item alignItems={"center"} sx={{display: "flex"}}>
                    <Typography variant={"body2"} sx={{fontWeight: 700, mr: {xs: "20px", md: "70px"}}}>{email}</Typography>
                    <GreenButton onClick={handleLogout} sx={{p:{xs: "6px 12px", md: "15px 48px"}, fontSize: {xs: "16px", md: "18px"}}}>Wyloguj się</GreenButton>
                </Grid>
            </Grid>
            <Grid item container sx={{width: "100%", height: "100%"}} alignItems={"center"}>
                <Grid item container sx={{backgroundColor: palette.secondary.light, height: "456px", mb: "100px"}} flexDirection={"column"} alignItems={"center"} flexWrap={"nowrap"}>
                    <Typography variant={"h5"} color={"white"} sx={{m: "48px 0"}}>Stan Twojego konta</Typography>
                    <Grid item container alignItems={"center"} flexDirection={"row"} justifyContent={"center"} sx={{minHeight: "176px"}}>
                        <Grid item xs={12} md={5} sx={{display: "flex", alignItems: "center", justifyContent: {xs:"center", md:"end"}}}>
                            <Box component={"img"} src={avatar} alt={"avatar"}
                                  sx={{
                                      minWidth: "176px",
                                      minHeight: "176px",
                                      maxWidth: "176px",
                                      maxHeight: "176px",
                                      verticalAlign: "middle",
                                      borderRadius: "50%",
                                      mr: "24px",
                                  }}/>
                            <Typography variant={"h5"} color={palette.secondary.main}>{firstName} {lastName}</Typography>
                        </Grid>
                        <Grid item xs={12} md={2} sx={{height: {xs:"0", md:"100%"}, display: "flex"}} justifyContent={"center"}>
                            <Divider orientation="vertical" sx={{backgroundColor: palette.secondary.main}}/>
                        </Grid>
                        <Grid item xs={12} md={5} sx={{display: "flex", justifyContent: {xs: "center", md: "start"}, mt: {xs: "48px", md: 0}}}>
                            <Typography variant={"h4"} color={"white"} sx={{zIndex: 50000}}>{amount} zł</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant={"h6"} color={"white"} sx={{zIndex: 50000, m: {xs:"24px 0", md: "48px 0"}}}>Data aktualizacji: {toLocaleDateTime(updateDttm)}</Typography>
                </Grid>
            </Grid>
            <Bubble src={darkBubble} top={"60%"} left={"calc(100% - 200px)"}/>
            <Bubble src={smallLightBubble} top={"calc(100% - 250px)"} left={"25%"}/>
            <Bubble src={largeLightBubble} top={"calc(100% - 130px)"} left={"10%"}/>
        </Grid>
    </BlankLayout>
}

export default AccountPage;