import {FormControlLabel, FormGroup, Grid, Typography, useTheme} from "@mui/material";
import {StyledTextField} from "../../ui/StyledTextfield";
import {useContext, useReducer, useState} from "react";
import isEmail from 'validator/lib/isEmail';
import isPostalCode from 'validator/lib/isPostalCode';
import SnackbarContext from "../../store/snackbar-context";
import PaymentContext from "./payment-context";
import CustomCheckbox from "../../ui/CustomCheckbox";
import {GreenButton} from "../../ui/GreenButton";

type PersonalDataInputProps = {
    onNext: () => void;
    onBack: () => void;
    agreements: any[];
}

const SET_FIRST_NAME = 'setFirstName';
const SET_LAST_NAME = 'setLastName';
const SET_STREET = 'setStreet';
const SET_CITY = 'setCity';
const SET_EMAIL = 'setEmail';
const SET_ZIP_CODE = 'setZipCode';
const SET_VALIDATE = 'setValidate';

type FormStateType = {
    firstName: string;
    lastName: string;
    email: string;
    street: string;
    city: string;
    zipCode: string;
    errors: {
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        street: string | null;
        city: string | null;
        zipCode: string | null;
    };
    validated: boolean;
}

type ActionType = {
    type: string;
    value?: any;
};

const formReducer = (state: FormStateType, action: ActionType): any => {
    switch (action.type) {
        case SET_FIRST_NAME: {
            return {
                ...state,
                firstName: action.value,
                errors: {...state.errors, firstName: action.value.length == 0 ? "Wprowadź poprawne imię" : null}
            };
        }
        case SET_LAST_NAME: {
            return {
                ...state,
                lastName: action.value,
                errors: {...state.errors, lastName: action.value.length == 0 ? "Wprowadź poprawne nazwisko" : null}
            };
        }
        case SET_EMAIL: {
            return {
                ...state,
                email: action.value,
                errors: {...state.errors, email: !isEmail(action.value) ? "Wprowadź poprawny email" : null}
            };
        }
        case SET_STREET: {
            return {
                ...state,
                street: action.value,
            };
        }
        case SET_CITY: {
            return {
                ...state,
                city: action.value,
            };
        }
        case SET_ZIP_CODE: {
            return {
                ...state,
                zipCode: action.value,
                errors: {...state.errors, email: action.value !== "" && isPostalCode(action.value, "PL") ? "Wprowadź poprawny email" : null}
            };
        }
        case SET_VALIDATE: {
            return {
                ...state,
                isValidated: true,
            };
        }
        default:
            return state;
    }
};

const PersonalDataInput = ({onNext, onBack, agreements}: PersonalDataInputProps) => {
    const {palette} = useTheme();
    const [formAgreements, setFormAgreements] = useState(agreements.map(agr => ({name: agr.text, isChecked: false})));
    const {setPersonalDetails, firstName, lastName, email, street, city, zipCode} = useContext(PaymentContext);
    const INITIAL_FORM_STATE = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        street: street,
        city: city,
        zipCode: zipCode,
        errors: {
            firstName: firstName !== "" ? "" : "Wprowadź poprawne imię",
            lastName: lastName !== "" ? "" : "Wprowadź poprawne nazwisko",
            email: email !== "" ? "" : "Wprowadź poprawny email",
            street: null,
            city: null,
            zipCode: null,
        },
        isValidated: false,
    }
    const [formState, dispatch] = useReducer(formReducer, INITIAL_FORM_STATE);
    const {setMsg} = useContext(SnackbarContext);

    const tickAgreement = (index: number) => {
        formAgreements[index] = {...formAgreements[index], isChecked: !formAgreements[index].isChecked};
        setFormAgreements([...formAgreements]);
    }

    const handleGoNext = () => {
        dispatch({type: SET_VALIDATE})
        if(validateForm()) {
            setPersonalDetails({
                firstName: formState.firstName,
                lastName: formState.lastName,
                email: formState.email,
                street: formState.street,
                city: formState.city,
                zipCode: formState.zipCode,
            })
            onNext();
        };
    }

    const validateForm = () => {
        for(const item in formState.errors) {
            if(formState.errors[item]) {
                setMsg({msg: formState.errors[item], severity: "error"});
                return false;
            }
        }
        if(formAgreements.filter(agr => !agr.isChecked).length > 0) {
            setMsg({msg: "Zaznacz wszystkie wymagane zgody", severity: "error"});
            return false;
        }
        return true;
    }


    return <Grid item container alignItems={"center"} flexDirection={"column"} mt={"50px"} wrap={"nowrap"}>
        <Grid item container  alignItems={"start"} flexDirection={"column"} sx={{maxWidth: "680px", p:{xs: "24px", md: 0}}}>
            <Grid item container justifyContent={"space-between"} alignItems={"center"} flexDirection={{xs: "column", md: "row"}}>
                <StyledTextField placeholder={"Imię*"} error={formState.isValidated && formState.errors.firstName} value={formState.firstName} onChange={({target:{value}}) => dispatch({type: SET_FIRST_NAME, value})} sx={{width: {xs: "90%", md: "48%"}}}/>
                <StyledTextField placeholder={"Ulica i numer"}  value={formState.street} onChange={({target:{value}}) => dispatch({type: SET_STREET, value})} sx={{width: {xs: "90%", md: "48%"}, mt: {xs: "4%", md: 0}}}/>
            </Grid>
            <Grid item container justifyContent={"space-between"} alignItems={"center"} flexDirection={{xs: "column", md: "row"}} mt={"4%"}>
                <StyledTextField placeholder={"Nazwisko*"} error={formState.isValidated && formState.errors.lastName} value={formState.lastName} onChange={({target:{value}}) => dispatch({type: SET_LAST_NAME, value})} sx={{width: {xs: "90%", md: "48%"}}}/>
                <StyledTextField placeholder={"Miasto"}  value={formState.city} onChange={({target:{value}}) => dispatch({type: SET_CITY, value})} sx={{width: {xs: "90%", md: "48%"}, mt: {xs: "4%", md: 0}}}/>
            </Grid>
            <Grid item container justifyContent={"space-between"} alignItems={"center"} flexDirection={{xs: "column", md: "row"}} mt={"4%"}>
                <StyledTextField placeholder={"Adres e-mail*"}  error={formState.isValidated && formState.errors.email} value={formState.email} onChange={({target:{value}}) => dispatch({type: SET_EMAIL, value})} sx={{width: {xs: "90%", md: "48%"}}}/>
                <StyledTextField placeholder={"Kod pocztowy"}  value={formState.zipCode} onChange={({target:{value}}) => dispatch({type: SET_ZIP_CODE, value})} sx={{width: {xs: "90%", md: "48%"}, mt: {xs: "4%", md: 0}}}/>
            </Grid>
            <Typography variant={"body2"} textAlign={"start"} sx={{width: "100%", mt: {xs:"48px", md: "16px"}, color: palette.primary.light}}>*Pola obowiązkowe</Typography>
            <FormGroup sx={{mt: "17px", mb: "24px"}}>
                {formAgreements.map((agr: any, index: number) =>
                    <FormControlLabel
                        control={<CustomCheckbox isError={(!agr.isChecked && !formState.isValidated)} checkboxProps={{checked: agr.isChecked, onChange: () => tickAgreement(index), name: "agreement"}} />}
                        label={agr.name}
                        sx={{fontSize: "", fontWeight: 400, color: palette.primary.light}}
                    />
                )}
            </FormGroup>
            <Typography variant={"body2"} color={palette.primary.light} sx={{fontWeight: 700, mb: "7px"}}>Twoje bezpieczeństwo</Typography>
            <Typography variant={"body2"} color={palette.primary.light}>Nie musisz się martwić, Twoje dane są w pełni bezpieczne! Wszystkie informacje, które podasz na naszej stronie są w pełni szyfrowane. Więcej znajdziesz w Polityce prywatności i Regulaminie strony.</Typography>
        </Grid>
       <Grid item container sx={{p: {xs:"24px 24px", md: "48px 100px"}}} justifyContent={{xs:"center", md: "end"}}>
            <GreenButton onClick={() => onBack()} sx={{
                mr: "48px",
                backgroundColor: "white",
                color: palette.secondary.main}}>Wróć</GreenButton>
            <GreenButton onClick={() => handleGoNext()}>Dalej</GreenButton>
        </Grid>
    </Grid>
}

export default PersonalDataInput;