import {Button, Grid, styled, Typography, useTheme} from "@mui/material";
import {useContext, useRef} from "react";
import CvFormContext, {
    CITY,
    CV_FILE,
    DATE_OF_BIRTH,
    EDUCATION,
    EDUCATION_YEAR,
    EMAIL,
    FIRST_NAME,
    LAST_NAME,
    PHONE,
    STREET,
    VALIDATE_FIRST_STEP,
    ZIP_CODE
} from "./cv-form-context";
import SnackbarContext from "../../store/snackbar-context";
import checked_icon from "../../assets/images/check-round.svg"
import {StyledField} from "./CvForm";
import {GreenButton} from "../../ui/GreenButton";
import {StyledLabel} from "../../ui/StyledTextfield";

const WhiteButton = styled(Button)(({theme}) => ({
    padding: "20px 48px",
    width: "230px",
    marginTop: "32px",
    borderRadius: "100px",
    border: `1px solid ${theme.palette.secondary.main}`,
    fontWeight: 700,
    fontSize: "18px",
    textTransform: "none",
    whiteSpace: "nowrap",
    backgroundColor: "white",

    color: theme.palette.secondary.main,
    "&:hover": {
        backgroundColor: theme.palette.grey[100],
    }
}))

type Props = {
    onNext: () => void;
}

const PersonalData = ({onNext}: Props) => {
    const {palette} = useTheme();
    const {fields, cvQuestions, setter, isFirstStepValidated, cvFile} = useContext(CvFormContext);
    const textFields = [FIRST_NAME, STREET, LAST_NAME, CITY, DATE_OF_BIRTH, ZIP_CODE, EMAIL, PHONE];
    const questions = [EDUCATION, EDUCATION_YEAR];
    const hiddenFileInput = useRef<any>({});
    const {setMsg} = useContext(SnackbarContext);

    const handleUploadClick = () => {
        if(cvFile === null && hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.click()
        }
    };

    const handleUploadChange = (event: any) => {
        const fileUploaded = event.target.files[0];
        setter({type: CV_FILE, value: fileUploaded});
    };

    const handleGoNext = () => {
        setter({type: VALIDATE_FIRST_STEP});
        const error = getErrors();
        if(error) {
            setMsg({msg: error, severity: "error"})
        } else {
            onNext();
        }
    }

    const getErrors = () => {
        let result = null;
        textFields.forEach(key => {
            const field = fields.get(key);
            if(field && field.error) result = field.error;
        })
        if(cvFile === null) {
            questions.forEach(key => {
                const field = cvQuestions.get(key);
                if(field && field.error) result =  field.error;
            })
        }
        return result;
    }

    return <Grid item container sx={{p: "0 64px"}} flexDirection={"column"}>
        <Typography variant={"h5"} mt={"48px"} mb={"48px"} color={palette.secondary.main} textAlign={"left"} sx={{fontWeight: 700}}>Dane osobowe</Typography>
        <Grid item container spacing={4}>
            {textFields.map(key => {
                const field = fields.get(key);
                return field && <Grid item xs={12} sm={6} key={key}>
                    <StyledLabel htmlFor={field.label}>{field.label}</StyledLabel>
                    <StyledField fullWidth id={field.label} value={field.value} placeholder={field.placeholder} error={field.error != "" && isFirstStepValidated}
                                     onChange={({target: {value}}) => setter({type: key, value: value})}/>
                </Grid>
            })}
        </Grid>
        <Typography variant={"h5"} mt={"64px"} mb={"24px"} color={palette.secondary.main} textAlign={"left"} sx={{fontWeight: 700}}>Edukacja i doświadczenie zawodowe</Typography>
        {questions.map(key => {
            const field = cvQuestions.get(key);
            return field && <Grid item xs={12} key={key} mb={"24px"}>
                <StyledLabel htmlFor={field.label}>{field.label}</StyledLabel>
                <StyledField fullWidth id={field.label} value={field.value} placeholder={field.placeholder} error={cvFile === null && field.error != "" && isFirstStepValidated}
                             onChange={({target: {value}}) => setter({type: key, value: value})}/>
            </Grid>
        })}
            <Typography variant={"body2"} mt={"16px"} color={palette.primary.light} textAlign={"left"}>*Pola obowiązkowe</Typography>
            <Typography variant={"body2"} mt={"16px"} color={palette.primary.light} textAlign={"center"} sx={{fontWeight: 700}}>lub</Typography>
            <WhiteButton sx={{margin: "auto", mt: "32px"}} onClick={handleUploadClick}>
                {cvFile ? <img src={checked_icon} alt={"Uploaded"}/> : "Wgraj swoje CV"}
                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleUploadChange}
                    style={{display: 'none'}}
                />
            </WhiteButton>
        <Grid item alignSelf={{xs:"center", sm: "end"}} mt={"48px"} mb={"48px"}>
            <GreenButton onClick={() => handleGoNext()}>Dalej</GreenButton>
        </Grid>
    </Grid>
}

export default PersonalData;