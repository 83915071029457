import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ContentContextProvider} from "./store/content-context";
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider, responsiveFontSizes} from "@mui/material";
import {SnackbarContextProvider} from "./store/snackbar-context";
import {AuthContextProvider} from "./store/auth-context";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import {PaymentContextProvider} from "./components/PaymentForm/payment-context";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

let theme = createTheme({
    palette: {
        primary: {
            main: "#424242",
            light: "#787885",
        },
        secondary: {
            light: "#AACE38",
            main: "#3D8D4F",
            dark: '#424242'
        },
    },
    typography: {
        fontSize: 16,
        fontFamily: [
            'Sen-Regular', 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            'sans-serif'
        ].join(','),
        body1: {
            fontSize: "17px",
            fontWeight: 400,
        },
        h1: {
            fontSize: "52px",
            fontWeight: 700,
        },
        h2: {
            fontSize: "48px",
            fontWeight: 700,
        },
        h4: {
            fontSize: "42px",
            fontWeight: 700,
        },
        h5: {
            fontSize: "32px",
            fontWeight: 700,
        },
        h6: {
            fontSize: "21px",
            fontWeight: 700,
        }
    },
});

theme = responsiveFontSizes(theme);

const payPalOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID ? process.env.REACT_APP_PAYPAL_CLIENT_ID : "",
    currency: "PLN",
    debug: false,
    locale: "pl_PL",
    components: "buttons",
    commit: true,
}

root.render(

        <ThemeProvider theme={theme}>
            <PayPalScriptProvider options={payPalOptions}>
                <PaymentContextProvider>
                    <SnackbarContextProvider>
                        <ContentContextProvider>
                            <AuthContextProvider>
                                <BrowserRouter>
                                    <App/>
                                </BrowserRouter>
                            </AuthContextProvider>
                        </ContentContextProvider>
                    </SnackbarContextProvider>
                </PaymentContextProvider>
            </PayPalScriptProvider>
        </ThemeProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
