import {Grid, Typography, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {GreenButton} from "../../ui/GreenButton";


const CvConfirmation = () => {
    const {palette} = useTheme();
    const navigate = useNavigate();

    return <Grid item container alignItems={"center"} flexDirection={"column"} mt={"50px"} wrap={"nowrap"}>
        <Grid item container flexDirection={"column"} alignItems={"center"} sx={{backgroundColor: palette.secondary.main, width: {xs: "90%", md: "528px"}, borderRadius: "5px"}}>
            <Typography variant={"h5"} color={"white"} m={"48px 45px 0 45px"} sx={{textAlign: "center", fontWeight: 700}}>Dziękujemy za przesłanie zgłoszenia!</Typography>
            <Typography variant={"body2"} color={"white"} m={"20px 45px 48px 45px"}>Pragniemy zaznaczyć, że z osobami, które przeszły do następnego etapu rekrutacji, skontaktujemy się osobiście drogą mailową lub telefonicznie.</Typography>
        </Grid>
        <Grid item container sx={{m: "40px 0 80px 0", width: {xs: "90%", md: "100%"}}} justifyContent={"center"}>
            <GreenButton onClick={() => navigate("/")} sx={{
                mb: {xs: "24px", md: 0},
                backgroundColor: "white",
                color: palette.secondary.main}}>Zamknij</GreenButton>
        </Grid>
    </Grid>
}

export default CvConfirmation;