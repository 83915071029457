import {Box, Button, Grid, InputAdornment, styled, Typography, useTheme} from "@mui/material";
import {useContext, useState} from "react";
import DollarIcon from "../../ui/icons/DolarIcon";
import payPalLogo from "../../assets/images/paypal.png";
import SnackbarContext from "../../store/snackbar-context";
import PaymentContext from "./payment-context";
import {StyledTextField} from "../../ui/StyledTextfield";
import {GreenButton} from "../../ui/GreenButton";

const AmountButton = styled(Button)(({theme}) => ({
    padding: "8px 34px",
    marginTop: "32px",
    borderRadius: "100px",
    border: `1px solid ${theme.palette.secondary.main}`,
    fontWeight: 700,
    fontSize: "18px",
    textTransform: "none",
    whiteSpace: "nowrap",
    backgroundColor: "white",

    color: theme.palette.secondary.main,
    "&:hover": {
        backgroundColor: theme.palette.grey[100],
    }
}))

type AmountInputProps = {
    onNext: () => void;
}

const AmountInput = ({onNext}: AmountInputProps) => {
    const {palette} = useTheme();
    const {setMsg} = useContext(SnackbarContext);
    const {setPayment, type, amount} = useContext(PaymentContext);
    const [paymentDetails, setPaymentDetails] = useState<{type: "single" | "regular", amount: number}>({type: type, amount: amount})
    const defaultAmounts = [10,25,50,100];

    const handleTextInputChange = (event: any) => {
        const reg = new RegExp('^[0-9]*$');
        if (reg.test(event.target.value)) {
            setPaymentDetails({...paymentDetails, amount: event.target.value});
        }
    }

    const handleGoNext = () => {
        if(paymentDetails.amount > 0) {
            setPayment({
                type: paymentDetails.type,
                amount: paymentDetails.amount,
            });
            onNext();
        } else {
            setMsg({msg: "Uzupełnij kwotę!", severity: "warning"});
        }
    }

    return <Grid item container alignItems={"center"} flexDirection={"column"} mt={{xs:"48px", md:"98px"}}>
        <Grid item container justifyContent={"center"} alignItems={"center"} flexDirection={{xs: "column", md: "row"}}>
            <GreenButton sx={{
                backgroundColor: paymentDetails.type === "single" ? palette.secondary.main : "white",
                color: paymentDetails.type === "single" ? "white" : palette.secondary.main,
                mr: {xs: 0, md: "48px"},
                mb: {xs: "24px", md: 0},
            }}
                         onClick={() => setPaymentDetails({...paymentDetails, type: "single"})}
            >
                Płatność jednorazowa
            </GreenButton>
            <GreenButton sx={{
                backgroundColor: paymentDetails.type === "regular" ? palette.secondary.main : "white",
                color: paymentDetails.type === "regular" ? "white" : palette.secondary.main}}
                         onClick={() => setPaymentDetails({...paymentDetails, type: "regular"})}
            >
                Płatność miesięczna
            </GreenButton>
        </Grid>
        <Grid item container flexDirection={"column"} sx={{maxWidth: "490px", mt: "80px", pl: {xs: "12px", md: 0}, pr: {xs: "12px", md: 0}}}>
            <StyledTextField variant={"outlined"}
                             value={paymentDetails.amount != 0 ? paymentDetails.amount : undefined}
                             onChange={handleTextInputChange}
             InputProps={{
                startAdornment: <InputAdornment position="start" sx={{margin: "0 15px"}}><DollarIcon/></InputAdornment>,
            }}
             placeholder={"Wpisz kwotę"}
            />
            <Grid item container justifyContent={"space-between"}>
                {defaultAmounts.map((amt: number, index: number) =>
                    <AmountButton key={index}
                                  sx={{
                                      backgroundColor: amt === paymentDetails.amount ? palette.secondary.main : "white",
                                      color: amt === paymentDetails.amount ? "white" : palette.secondary.main}}
                                  onClick={() => setPaymentDetails({...paymentDetails, amount: amt})}
                    >{amt} zł
                    </AmountButton>
                )}
            </Grid>
        </Grid>
        <Grid item container sx={{p: "100px 100px 48px 100px"}} wrap={"nowrap"} justifyContent={"space-between"}>
            <Grid item flexDirection={"column"} alignItems={"center"} sx={{display: "flex"}}>
                <Typography>Płacę z</Typography>
                <Box component={"img"} sx={{width: "119px"}} src={payPalLogo} alt={"PayPal logo"}/>
            </Grid>
            <Grid item>
                <GreenButton onClick={() => handleGoNext()}>Dalej</GreenButton>
            </Grid>
        </Grid>
    </Grid>
}

export  default AmountInput;
