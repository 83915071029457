import {Grid, Typography, useTheme} from "@mui/material";
import DOMPurify from "dompurify";

type Props = {
    content: any;
}

const GreenBgText = ({content}: Props) => {
    const {palette} = useTheme();

    return <Grid container justifyContent={"center"} flexDirection={"column"} alignItems={"center"} sx={{width: "100%", backgroundColor: palette.secondary.main}}>
        <Grid item sx={{width: {lg: "1175px", xs: "90%"}, p: "96px 0", m: "0 auto", color: "white", zIndex: 1}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content.text)}}/>
    </Grid>
}

export default GreenBgText;