import {Box, Grid, styled, Typography, useTheme} from "@mui/material";
import UpperQuote from "../ui/icons/UpperQuote";
import LowerQuote from "../ui/icons/LowerQuote";

type Props = {
    content: any;
}

const AvatarImage = styled("img")({
    width: "200px",
    height: "200px",
    verticalAlign: "middle",
    borderRadius: "50%",
})

const QuoteWithImage = ({content}: Props) => {
    const {palette} = useTheme();

    return <Grid container justifyContent={"center"} flexDirection={"column"} alignItems={"center"} sx={{width: "100%", backgroundColor: palette.grey[100]}}>
        {content.title !== "" && <Typography variant={"h5"} mt={"96px"} color={palette.secondary.main}>{content.title}</Typography>}
        <Grid item container alignItems="center" flexDirection={{xs: "column", md: "row"}} wrap={"nowrap"} sx={{width: {xs: "90%", lg:"70%", xl:"55%"}, mt:content.title !== "" ? "60px" : "96px", mb: "96px"}}>
            <Grid item container justifyContent={"center"} alignItems={"center"} sx={{minWidth: "220px",maxWidth: "220px", minHeight: "220px", maxHeight: "220px",borderRadius: "50%", m: {xs: "0 40px 40px 40px", md: "40px"}, border: "1px solid " + palette.secondary.light}} >
                {content.image.sizes.medium && <AvatarImage src={content.image.sizes.medium} alt={content.image.alt}/>}
            </Grid>
            <Grid item container direction={"column"}>
                <UpperQuote/>
                <Box sx={{ml: "50px"}}>
                    <Typography variant={"body1"} color={"primary"}>{content.quote}</Typography>
                    <Box sx={{display: "flex", justifyContent: "end"}}>
                        <LowerQuote/>
                    </Box>
                    <Typography variant={"caption"}  color={"primary"}>{content.job_title}</Typography>
                    <Typography variant={"h6"}  color={"primary"}>{content.fullname}</Typography>
                </Box>
            </Grid>
        </Grid>
    </Grid>
}

export default QuoteWithImage;