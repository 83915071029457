import {Dialog, Grid, TextField, Typography, useTheme} from "@mui/material";
import {StyledLabel, StyledTextField} from "../../ui/StyledTextfield";
import {useContext, useState} from "react";
import isEmail from "validator/lib/isEmail";
import {GreenButton} from "../../ui/GreenButton";
import SnackbarContext from "../../store/snackbar-context";
import {createUser, updateUser} from "../../api/user-api";
import AuthContext from "../../store/auth-context";
import {useNavigate} from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs, { Dayjs } from 'dayjs';

type Props = {
    user?: any;
    open: boolean;
    onClose: () => void;
}

const UserDialog = ({user, open, onClose}: Props) => {
    const {palette} = useTheme();
    const [isValidated, setIsValidated] = useState(false);
    const [firstName, setFirstName] = useState(user ? user.firstName : "");
    const [lastName, setLastName] = useState(user ? user.lastName : "");
    const [email, setEmail] = useState<string>(user ? user.email : "");
    const [account, setAccount] = useState(user ? user.account : "");
    const [login, setLogin] = useState(user ? user.login : "");
    const [password, setPassword] = useState("");
    const {token, logout, isAdmin} = useContext(AuthContext);
    const navigate = useNavigate();
    const {setMsg} = useContext(SnackbarContext);
    const [updateDttm, setUpdateDttm] = useState<Dayjs | null>(dayjs());

    const handleChange = (newValue: Dayjs | null) => {
        setUpdateDttm(newValue);
    };

    const handleOkClick = () => {
        setIsValidated(true);
        if(!validateForm()) {
            setMsg({msg: "Upewnij się, że wszystkie pola są poprawnie wypełnione!"})
            return;
        }
        if(user) {
            if(token && isAdmin) {
                updateUser({id: user.id, firstName, lastName, email, password, account, updateDttm}, token, () => {
                    logout();
                    navigate('/login');
                })
                    .then((response) => {
                        setMsg({msg: "Dane użytkonika zostały zaktualizowane", severity: "info"});
                        onClose()
                        window.location.reload();
                    })
                    .catch((error) => setMsg({msg: "Wystąpił błąd. Spróbuj ponownie później."}))
            } else {
                navigate("/login")
            }
        } else {
            if(token && isAdmin) {
                createUser({login, firstName, lastName, email, password, account, updateDttm}, token, () => {
                    logout();
                    navigate('/login');
                })
                    .then((response) => {
                        setMsg({msg: "Dodano nowego użytkownika", severity: "info"});
                        onClose()
                        window.location.reload();
                    })
                    .catch((error) => setMsg({msg: "Wystąpił błąd. Spróbuj ponownie później."}))
            } else {
                navigate("/login")
            }
        }
    }

    const validateForm = () => {
        return firstName.length > 0 &&
            lastName.length > 0 &&
            isEmail(email) &&
            (user ? true : login.length > 0) &&
            (user ? true : password.length > 0) &&
            (user ? account.length > 0 : true);
    }

    return <Dialog open={open} onClose={onClose}>
        <Grid container flexDirection={"column"} sx={{p:"48px", width: "450px"}}>
            <Typography variant={"h5"} mb={"12px"} color={palette.secondary.main} sx={{fontWeight: 700}}>
                {user ? "Edytuj dane" : "Dane osobowe"}
            </Typography>
            <StyledLabel htmlFor={"login"} sx={{mt: "12px"}}>Login</StyledLabel>
            <StyledTextField id={"login"} value={login} disabled={user} onChange={({target: {value}}) => setLogin(value)} error={isValidated && login === ""}/>
            <StyledLabel htmlFor={"email"} sx={{mt: "12px"}}>Adres e-mail</StyledLabel>
            <StyledTextField id={"email"} value={email} onChange={({target: {value}}) => setEmail(value)} error={isValidated && !isEmail(email)}/>
            <StyledLabel htmlFor={"password"} sx={{mt: "12px"}}>Hasło</StyledLabel>
            <StyledTextField id={"password"} type={"password"} value={password} onChange={({target: {value}}) => setPassword(value)} error={isValidated && password === "" && !user}/>
            <StyledLabel htmlFor={"firstName"} sx={{mt: "12px"}}>Imię</StyledLabel>
            <StyledTextField id={"firstName"} value={firstName} onChange={({target: {value}}) => setFirstName(value)} error={isValidated && firstName === ""}/>
            <StyledLabel htmlFor={"lastName"} sx={{mt: "12px"}}>Nazwisko</StyledLabel>
            <StyledTextField id={"lastName"} value={lastName} onChange={({target: {value}}) => setLastName(value)} error={isValidated && lastName === ""}/>
            <StyledLabel htmlFor={"account"} sx={{mt: "12px"}}>Stan konta</StyledLabel>
            <StyledTextField id={"account"}  value={account} onChange={({target: {value}}) => setAccount(value)} error={isValidated && account === ""}/>
            <StyledLabel htmlFor={"updateDttm"} sx={{mt: "12px"}}>Data aktualizacji</StyledLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    label=""
                    inputFormat="DD/MM/YYYY"
                    maxDate={dayjs()}
                    value={updateDttm}
                    onChange={handleChange}
                    renderInput={(params) => <StyledTextField id={"updateDttm"} {...params} />}
                />
            </LocalizationProvider>
            <Grid item container justifyContent={"space-between"} sx={{mt: "48px"}}>
                <GreenButton onClick={onClose} sx={{color: palette.secondary.main, backgroundColor: "white"}}>{"Anuluj"}</GreenButton>
                <GreenButton onClick={handleOkClick}>{user ? "Zapisz" : "Utwórz"}</GreenButton>
            </Grid>
        </Grid>
    </Dialog>
}

export default UserDialog;