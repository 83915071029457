import {Box, Grid, IconButton, makeStyles, styled, TextField, Typography, useTheme} from "@mui/material";
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {useContext, useState, useRef, useEffect} from "react";
import pl from 'date-fns/locale/pl';
import ContentContext from "../store/content-context";
import '../assets/scss/Calendar.scss';
import DOMPurify from "dompurify";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import BubbleLeft from "../assets/images/left-dark-half-bubble.png";
import {useNavigate} from "react-router-dom";

type Props = {
    content: any;
}

type SliderButtonProps = {
    onClick: () => void;
    fwd: boolean;
}

const DayPicker = styled(PickersDay)(({theme}) => ({
    borderRadius: "25px",
    border: "none",
    backgroundColor: "transparent",
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: "none",
        },
    }
}))

const BoxDate = styled(Box)(({theme}) => ({
    color: 'white',
    textAlign: 'left',
    [theme.breakpoints.up("xl")]: {
        fontSize: '24px',
    },
    [theme.breakpoints.down("xl")]: {
        fontSize: '16px',
    },
}))

const BoxTitle = styled(Box)(({theme}) => ({
    color: 'white',
    lineHeight: '40px',
    fontWeight: 'bold',
    textAlign: 'left',
    '&:hover': {cursor: 'pointer'},
    [theme.breakpoints.up("xl")]: {
        fontSize: '36px',
    },
    [theme.breakpoints.down("xl")]: {
        fontSize: '20px',
    },
}))

const BoxOnImage = styled(Box)(({theme}) => ({
    position: 'relative',
    transition: 'all 1s',
    [theme.breakpoints.up("xl")]: {
        '&:not(:first-of-type)': {
            marginLeft: '30px'
        }
    },
}))

const BoxGrey = styled(Box)(({theme}) => ({
    position: 'absolute',
    bottom: '45px',
    left: 0,
    backgroundColor: 'rgb(66, 66, 66, .5)',
    zIndex: 2,
    [theme.breakpoints.up("xl")]: {
        width: "500px",
    },
    [theme.breakpoints.down("xl")]: {
        width: "280px",
    }
}))

const SliderImage = styled("img")(({theme}) => ({
    [theme.breakpoints.up("xl")]: {
        width: "909px",
        height: "550px",
        objectFit: 'cover',
    },
    [theme.breakpoints.down("xl")]: {
        width: "670px",
        height: "500px",
        objectFit: 'cover'
    },
    [theme.breakpoints.down("md")]: {
        width: "450px",
        height: "300px",
        objectFit: 'cover'
    },
    [theme.breakpoints.down("sm")]: {
        width: "280px",
        height: "240px",
        objectFit: 'cover'
    },
}))

const Calendar = ({content}: Props) => {
    const navigate = useNavigate();
    const {palette} = useTheme();
    const {events} = useContext(ContentContext);
    const {news} = useContext(ContentContext);
    const [allPastEvents, setAllPastEvents] = useState<any[]>([]);
    const calendar = useRef(null);
    let todayDate = new Date();

    const pastNews = news.filter((item) => {
        // @ts-ignore
        return item.displayLastNews === true;
    })

    const pastEvents = events.filter((item) => {
        // @ts-ignore
        return item.eventDate < todayDate && item.displayLastNews === true;
    })

    useEffect(() => {
        setAllPastEvents([...pastEvents, ...pastNews]);
    }, [])

    const futureEvents = events.filter((item) => {
        // @ts-ignore
        return item.eventDate >= todayDate;
    })

    const eventDates = events.map((item: {eventDate: any}, index: number) => {
        return item.eventDate
    });

    const dateDay = new Date();

    // @ts-ignore
    const sortFutureDate = futureEvents.sort((a, b) => a.eventDate - b.eventDate);
    // @ts-ignore
    const sortPastDate = allPastEvents.sort((a, b) => a.eventDate - b.eventDate);
    const [values, setValues] = useState([...eventDates]);
    const [propValue, setPropValues] = useState([...eventDates]);
    const [select, setSelect] = useState([dateDay]);


    useEffect(() => {
        if (sortFutureDate.length > 0) {
            // @ts-ignore
            setSelect([sortFutureDate[0].eventDate]);
        } else if (sortPastDate.length > 0) {
            // @ts-ignore
            setSelect([sortPastDate[0].eventDate]);
        } else {
            setSelect([dateDay])
        }
    }, [events])

    const findDate = (dates: any, date: any) => {
        const dateTime = date?.getTime();
        return dates.find((item: any) => item?.getTime() === dateTime);
    };

    const renderPickerDay = (date: any, selectedDates: any, pickersDayProps: any) => {
        if (!values) {
            return <DayPicker {...pickersDayProps} />;
        }

        const eventDay = findDate(eventDates, date);

        const selected = findDate(select, date);

        if(eventDay) {
            return (
                <DayPicker
                    {...pickersDayProps}
                    disableMargin
                    selected={selected}
                    disableHighlightToday={true}
                    className="active"
                />
            );
        } else {
            return (
                <DayPicker
                    {...pickersDayProps}
                    disableMargin
                    disableHighlightToday={true}
                />
            );
        }
    };

    const [indexFutureImg, setIndexFutureImg] = useState(-90);
    const [indexPastImg, setIndexPastImg] = useState(-60);

    const nextFutureSlide = () => {
        indexFutureImg === 90 ? setIndexFutureImg(-90 * (futureEvents.length - 1)) : setIndexFutureImg(indexFutureImg + 90)
    }

    const prevFutureSlide = () => {
        indexFutureImg === -90 * (futureEvents.length - 1) ? setIndexFutureImg(90) : setIndexFutureImg(indexFutureImg - 90)
    }

    const nextPastSlide = () => {
        indexPastImg === 60 ? setIndexPastImg(-60 * (allPastEvents.length)) : setIndexPastImg(indexPastImg + 60)
    }

    const prevPastSlide = () => {
        indexPastImg === -60 * (allPastEvents.length) ? setIndexPastImg(60) : setIndexPastImg(indexPastImg - 60)
    }

    const scrollEvent = () => {
        // @ts-ignore
        window.scrollTo(0, calendar.current.offsetTop)
    }

    const SliderButtonLight = ({onClick, fwd}: SliderButtonProps) => {
        const {palette} = useTheme();

        return <IconButton
            onClick={onClick}
            sx={{
                backgroundColor: 'white',
                "&:hover": {
                    backgroundColor: 'white',
                },
                color: palette.secondary.main,
                padding: {xl: "25px", md: "20px", xs: "15px"},
                mr: "32px",
            }}>
            {fwd ?
                <ArrowForwardIosIcon/>
                :
                <ArrowBackIosIcon/>
            }
        </IconButton>
    }

    const SliderButtonDark = ({onClick, fwd}: SliderButtonProps) => {

        return <IconButton
            onClick={onClick}
            sx={{
                backgroundColor: 'secondary.main',
                "&:hover": {
                    backgroundColor: 'secondary.main',
                },
                color: 'white',
                padding: {xl: "25px", md: "20px", xs: "15px"},
                mr: "32px",
            }}>
            {fwd ?
                <ArrowForwardIosIcon/>
                :
                <ArrowBackIosIcon/>
            }
        </IconButton>
    }

    return <Grid container>
        <Grid container item sx={{backgroundColor: '#F4F4F4;'}} direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item pt={10} pb={1} xs={12}>
                <Typography variant="h5" textAlign={'center'} color="secondary.main">Kalendarz wydarzeń</Typography>
            </Grid>
            <Grid container item xs={12} pt={{xs: 2, lg: 6}} flexDirection={{xs: 'column', lg: 'row'}} justifyContent={events.length > 0 ? "flex-end" : "center"} alignItems="center" ref={calendar}>
                <Grid item xs={12} lg={6} xl={5} pr={{lg: 6, xl: 12}} pt={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl} dateFormats={{ monthAndYear: 'yyyy MMMM' }}  sx={{backgroundColor: 'black'}}>
                        <StaticDatePicker displayStaticWrapperAs="desktop"
                                          className="calendar"
                                          value={propValue}
                                          showDaysOutsideCurrentMonth={true}
                                          views={["day"]}
                                          onChange={(newValue) => {
                                              const array = [...values];
                                              setPropValues(values);
                                              const val = findDate(array, newValue);
                                              const result = array.filter((item) => val === item)
                                              {result.length === 0 ? setSelect(prevState => prevState) : setSelect(result)}
                                          }}
                                          renderInput={(params) => <TextField {...params} />}
                                          renderDay={renderPickerDay}
                        />
                    </LocalizationProvider>
                </Grid>
                    <>
                        {events.map((item, index: number) => {
                            if(item.eventDate?.getTime() === select[0]?.getTime()) {
                                return <Grid container item xs={12} lg={5} pt={{xs: 4, lg: 0}} pb={{xs: 4, lg: 0}} sx={{backgroundColor: palette.secondary.main, minHeight: { xs: 'auto', lg: '800px'}}} flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'}>
                                    <Grid item lg={11} xl={9} ml={{xs: 4, lg: 4, xl: 10}} mr={{xs: 4, lg: 0}}>
                                        <Box mr={{lg: 3, xl: 0}} sx={{fontSize: {xs: '24px', lg: '36px'}, color: 'white', letterSpacing: '1px', textAlign: 'right', width: {lg: 'auto', xl: '100%'}}}>{item.eventDate?.toLocaleDateString()}</Box>
                                        <Box pt={6} sx={{fontSize: {xs: '20px', lg: '24px'}, color: 'white', fontWeight: 'bold', letterSpacing: '1px', textAlign: 'left'}}>{item.title}</Box>
                                        <Box pt={2} sx={{fontSize: {xs: '16px', lg: '17px'}, color: 'white', letterSpacing: '.2px'}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.description)}} />
                                    </Grid>
                                </Grid>
                            }
                        })}
                    </>
            </Grid>
        </Grid>
        {sortFutureDate.length > 0 ?
        <Grid container item sx={{backgroundColor: 'secondary.light', position: 'relative'}} justifyContent="center">
            <Box component='img' sx={{
                position: 'absolute',
                top: {sm: '40px', lg: '-130px'},
                left: '-40px',
                zIndex: 2,
                display: {xs: 'none', lg: 'block'}
            }}
                 alt='Fundacja Pomocy Dzieciom'
                 src={BubbleLeft}
            />
            <Grid item pt={{xs: 10, lg: 18}} pb={{xs: 3, xl: 10}} xs={12}>
                <Typography variant="h5" textAlign={'center'} color="secondary.main">Nadchodzące wydarzenia</Typography>
            </Grid>
            <Grid item pb={{xs: 8, xl: 18}} sx={{position: 'relative', display: 'flex', overflow: 'hidden', flexDirection: {xs: `${sortFutureDate.length <= 2 ? 'column' : 'row'}`, xl: 'row'}}}>
                <>
                    {sortFutureDate.map((item, index: number) => {
                        return (
                            <BoxOnImage mt={{xs: 4, xl: 0}} sx={{transform: `translateX(${sortFutureDate.length <= 2 ? 0 : indexFutureImg}%)`, marginLeft: `${sortFutureDate.length <= 2 ? 0 : '30px'}`}}>
                                <BoxGrey>
                                    <BoxDate pt={{xs: 2, xl: 4}} pl={{xs: 2, xl: 4}}>{item.eventDate?.toLocaleDateString()}</BoxDate>
                                    <BoxTitle pt={{xs: 3, xl: 6}} pb={{xs: 2, xl: 4}} pl={{xs: 2, xl: 4}} mr={{xs: 2, xl: 4}} onClick={() => { // @ts-ignore
                                        setSelect([item.eventDate]); setPropValues([item.eventDate]); scrollEvent()}}>{item.title}</BoxTitle>
                                </BoxGrey>
                                <SliderImage sx={{zIndex: 1}} src={item.image.url} alt={item.image.alt} />
                            </BoxOnImage>
                        )
                    })}
                </>
                {sortFutureDate.length <= 2 ? "" : <Box sx={{position: "absolute", bottom: {xs: '20px', lg: "130px"}, right: {xs: '70px', lg: "130px"}}}>
                    <SliderButtonLight fwd={false} onClick={nextFutureSlide}/>
                    <SliderButtonLight fwd={true} onClick={prevFutureSlide}/>
                </Box>}
            </Grid>
        </Grid> : '' }
        {sortPastDate.length > 0 ?
        <Grid container item justifyContent="center">
            <Grid item pt={{xs: 10, lg: 18}} pb={{xs: 3, xl: 10}} xs={12}>
                <Typography variant="h5" textAlign={'center'} color="secondary.main">Minione wydarzenia</Typography>
            </Grid>
            <Grid item pb={{xs: 8, xl: 18}} sx={{position: 'relative', display: 'flex', overflow: `hidden`, flexDirection: {xs: `${sortPastDate.length <= 2 ? 'column' : 'row'}`, xl: 'row'}}}>
                <>
                    {sortPastDate.map((item, index: number) => {
                        return (
                            <BoxOnImage mt={{xs: 4, xl: 0}} sx={{transform: `translateX(${sortPastDate.length <= 2 ? 0 : indexPastImg}%)`, marginLeft: `${sortPastDate.length <= 2 ? 0 : '30px'}`}}>
                                <BoxGrey>
                                    <BoxDate pt={{xs: 2, xl: 4}} pl={{xs: 2, xl: 4}}>{item.eventDate?.toLocaleDateString()}</BoxDate>
                                    <BoxTitle pt={{xs: 3, xl: 6}} pb={{xs: 2, xl: 4}} pl={{xs: 2, xl: 4}} mr={{xs: 2, xl: 4}} onClick={() => { // @ts-ignore
                                        item.type === 'news' ? navigate(item.path) : setSelect([item.eventDate]); setPropValues([item.eventDate]); scrollEvent();}}>{item.title}</BoxTitle>
                                </BoxGrey>
                                <SliderImage sx={{zIndex: 1}} src={item.image.url} alt={item.image.alt} />
                            </BoxOnImage>
                        )
                    })}
                </>
                {sortPastDate.length <= 2 ? '' : <Box sx={{position: "absolute", bottom: {xs: '20px', lg: "130px"}, right: {xs: '70px', lg: "130px"}}}>
                    <SliderButtonDark fwd={false} onClick={nextPastSlide}/>
                    <SliderButtonDark fwd={true} onClick={prevPastSlide}/>
                </Box>}
            </Grid>
        </Grid> : '' }
    </Grid>
}

export default Calendar;
