import {Grid, Typography} from "@mui/material";

type Props = {
    content: any;
}

const HeaderWithImage = ({content}: Props) => {
    return  <Grid item container justifyContent="center" alignItems={"end"}
        sx={{
        zIndex: 1,
        position: 'relative',
        width: '100%',
        minHeight: {sm:'607px', xs:"400px"},
        backgroundImage: `url(${content.image.url})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    }}>
        <Typography variant={"h2"} color='white' textAlign={"center"}
            sx={{
                borderBottom: "4px solid white",
                width: {md:"512px", xs: "80%"},
                pb: "20px",
                mb: "62px"
            }}
        >
            {content.title}
        </Typography>
    </Grid>
}

export default HeaderWithImage;
