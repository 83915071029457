import {ReactNode, useContext} from "react";
import {Alert, Box, Snackbar} from "@mui/material";
import SnackbarContext from "../store/snackbar-context";

const BlankLayout = ({children}: {children: ReactNode}) => {
    const {msg, severity, setMsg} = useContext(SnackbarContext);

    return (
        <>
            <Box component={"main"}>
                {children}
            </Box>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={msg != ""} onClose={() => setMsg({msg: ""})}>
                <Alert severity={severity}>{msg}</Alert>
            </Snackbar>
        </>
    );
}

export default BlankLayout;
