import {Box, Button, Grid, styled, Typography, useTheme} from "@mui/material";
import ArrowRight from "../ui/icons/ArrowRight";

type Props = {
    content: any;
}

const ImageWithTitleDescription = ({content}: Props) => {
    const {palette} = useTheme();

    const ButtonStyled = styled(Button)({
        padding: '14px 48px',
        color: palette.secondary.main,
        border: '2px solid #3D8D4F',
        borderRadius: '32px',
        textTransform: 'none',
        fontWeight: '700',
        fontSize: '18px',
        marginTop: '24px'
    }) as typeof Button

    const BoxImageStyled = styled(Box)({
        objectFit: 'cover',
        width: '100%',
        maxWidth: '847px'
    }) as typeof Box

    const TypographyStyled = styled(Typography)({
        width: `{xs: '100%', lg: '90%'}`
    })

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" xs={12}
              sx={{
                  backgroundColor: palette.grey[100],
                  position: 'relative',
                  zIndex: 3
              }}>
            <Grid item pt={10} pb={8}>
                <Typography variant={'h5'} color={palette.secondary.main}>{content.title}</Typography>
            </Grid>
            <Grid container item pb={8}>
                {content.guides
                    &&
                    content.guides.length > 0
                    &&
                    content.guides.map((g: any, i: number) => (
                        <Grid key={'image-with-title-' + i} container item xs={12} pb={8} alignItems="center"
                              sx={{justifyContent: {xs: 'center', lg: i % 2 !== 0 ? 'flex-end' : 'flex-start'}}}>
                            <Grid item xs={12} lg={6} sx={{
                                display: 'flex',
                                justifyContent: {xs: 'center', lg: i % 2 !== 0 ? 'flex-end' : 'flex-start'},
                                order: i % 2 !== 0 ? {xs: 1, lg: 2} : ''
                            }}>
                                <BoxImageStyled component='img' sx={{
                                    objectFit: 'cover',
                                    width: '100%',
                                    maxWidth: '847px'
                                }}
                                                alt={content.guides[i].img.alt}
                                                src={content.guides[i].img.url}
                                />
                            </Grid>
                            <Grid item xs={12} lg={5} xl={4} pl={{xs: 0, lg: 6}} sx={{
                                textAlign: {xs: 'center', lg: 'left'},
                                order: i % 2 !== 0 ? {xs: 2, lg: 1} : ''
                            }}>
                                <TypographyStyled variant='h5'
                                                  pt={{xs: 8, lg: 0}}>{content.guides[i].title}</TypographyStyled>
                                <TypographyStyled variant='body1' mt={2}>{content.guides[i].authors}</TypographyStyled>
                                <ButtonStyled
                                    href={content.guides[i].button.url}>{content.guides[i].button.title}</ButtonStyled>
                            </Grid>
                        </Grid>
                    ))
                }
                <Grid item xs={12} lg={11} pt={{xs: 10, lg: 14}} pb={4} mr={{xs: 0, sm: 4, lg: 0}}
                      sx={{display: 'flex', justifyContent: {xs: 'center', sm: 'flex-end'}, alignItems: 'center'}}>
                    {content.button_more &&
                        content.button_more.length > 0
                        &&
                        content.button_more.map((b: any, i: number) => (
                            <Button key={'button-more-' + i} href={b.link.url} endIcon={<ArrowRight/>} sx={{
                                color: `${palette.primary}`,
                                textTransform: 'none',
                                fontWeight: '700',
                                fontSize: '17px'
                            }}>{b.button}</Button>
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ImageWithTitleDescription;
