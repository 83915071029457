import {ReactNode, useContext} from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer";
import {Alert, Box, Snackbar} from "@mui/material";
import SnackbarContext from "../store/snackbar-context";
import FloatingBanner from "../components/FloatingBanner";

const MainLayout = ({children}: {children: ReactNode}) => {
    const {msg, severity, setMsg} = useContext(SnackbarContext);

    return (
        <>
            <Header/>
                <Box component={"main"}>
                    {children}
                </Box>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={msg != ""} onClose={() => setMsg({msg: ""})}>
                <Alert severity={severity}>{msg}</Alert>
            </Snackbar>
            <FloatingBanner/>
            <Footer/>
        </>
    );
}

export default MainLayout;
