import Logo from "../../ui/Logo";
import {
    AppBar,
    Box,
    ClickAwayListener,
    Collapse,
    Drawer,
    IconButton,
    Toolbar,
    Typography,
    useTheme
} from "@mui/material";
import {useContext, useState} from "react";
import ContentContext from "../../store/content-context";
import {NavLink, useNavigate} from "react-router-dom";
import {PayOnlineButton} from "./Header";
import {MenuItem} from "../../model/Menu";
import MenuIcon from "../../ui/icons/MenuIcon";
import AccountIcon from "../../ui/icons/AccountIcon";
import CloseIcon from "../../ui/icons/CloseIcon";


type Props = {
    menuItem: MenuItem;
    isActive: boolean;
    closeDrawer: () => void;
}

const MenuItemLink = ({menuItem, isActive, closeDrawer} : Props) => {
    const {palette} = useTheme();
    const [isExpanded, setIsExpanded] = useState(false);

    return <ClickAwayListener onClickAway={() => setIsExpanded(false)}><div>
        <Typography variant={"body1"} sx={{
        textTransform: "none",
        color: palette.primary.main,
        textAlign: "center",
        whiteSpace: "nowrap",
        fontWeight: 700,
        cursor: "pointer",
        marginBottom: "48px",
        borderBottom: isActive ? "1px solid black" : "none"
    }}
    onClick={ () => setIsExpanded(!isExpanded)}
    >
        {menuItem.title}
    </Typography>
        { menuItem.subMenu && menuItem.subMenu.length > 0 &&
        <Collapse in={isExpanded} >
            {menuItem.subMenu.map((item, index) => {
                return <NavLink to={item.url} style={{textDecoration: "none"}} key={index} onClick={closeDrawer}>
                    {({isActive}) => {
                        return <Typography variant={"body1"}
                                           sx={{textTransform: "none", color: palette.primary.main, fontWeight: isActive ? 700 : 400, textAlign: "center", marginBottom: "32px", borderBottom: isActive ? "1px solid black" : "none"}}>
                            {item.title}
                        </Typography>
                    }}
                </NavLink>
            })}
        </Collapse>}</div>
        </ClickAwayListener>
}

type MenuItemLinkWrapperProps = {
    menuItem: MenuItem;
    closeDrawer: () => void;
}

const MenuItemLinkWrapper = ({menuItem, closeDrawer}: MenuItemLinkWrapperProps) => {
    return <>{menuItem.subMenu && menuItem.subMenu.length > 0 ? <MenuItemLink  menuItem={menuItem} isActive={false} closeDrawer={closeDrawer}/> :
            <NavLink to={menuItem.url} style={{textDecoration: "none"}} onClick={closeDrawer}>{({isActive}) => <MenuItemLink  menuItem={menuItem} isActive={isActive} closeDrawer={closeDrawer}/>}</NavLink>}</>
}

const MinHeader = () => {
    const {menu} = useContext(ContentContext);
    const [openDrawer, setOpenDrawer] = useState(false);
    const navigate = useNavigate();

    return <><AppBar position="relative" sx={{height: {xs: "68px", sm: "75px", zIndex: 100}, backgroundColor: "white", maxWidth: "100%"}}
                     component={"header"}>
        <Toolbar variant="dense" sx={{ padding: "14px 5%", minHeight: "0px", maxWidth: "100%"}}>
            <Logo sx={{flex: 1, height: "100%"}}/>
            <IconButton onClick={() => navigate("/login")}>
                <AccountIcon />
            </IconButton>
            <IconButton sx={{marginLeft: "27px"}} onClick={() => setOpenDrawer(true)}>
                <MenuIcon />
            </IconButton>
        </Toolbar>
    </AppBar>
        <Drawer
            anchor={"right"}
            open={openDrawer}
            onClose={()=> setOpenDrawer(false)}
        >
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", width: "375px"}}>
                <IconButton onClick={() => setOpenDrawer(false)} sx={{margin: "64px 0"}}>
                    <CloseIcon />
                </IconButton>
                {menu.menuItems.map((item, index) => {
                    return <MenuItemLinkWrapper key={index} menuItem={item} closeDrawer={() => setOpenDrawer(false)}/>
                    })}
                <PayOnlineButton/>
            </Box>
        </Drawer>
    </>
}

export default MinHeader;
