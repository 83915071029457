import {Box, Collapse, Grid, Typography, useTheme} from "@mui/material";
import {WPImage} from "../model/WPImage";
import large_bubble from "../assets/images/large-light-bubble.png";
import small_bubble from "../assets/images/small-light-bubble.png";
import {useState} from "react";
import DOMPurify from "dompurify";

type Props = {
    content: any;
}

type AvatarImageProps = {
    avatar: WPImage;
    rounded?: boolean;
    hasDescription?: boolean;
    onClick: () => void;
}

type AvatarWrapperProps = {
    data: {
        title: string;
        avatar: WPImage;
        subtitle: string;
        description: string;
    };
    top?: boolean;
    rounded?: boolean;
    onClick: () => void;
}

type BubbleProps = {
    xl?: boolean;
    top: any;
    left: any;
}

const Bubble = ({xl, top, left}: BubbleProps) => {
    return <Box sx={{position: "absolute", top: top, left: left, zIndex: -10}}>
        <img src={xl ? large_bubble  : small_bubble} alt={"bubble_background"}/>
    </Box>
}

const AvatarImage = ({avatar, rounded, hasDescription, onClick}: AvatarImageProps) => {
    return <Grid item component={"img"} src={avatar.sizes.large}
         onClick={onClick}
         sx={{
        minWidth: "376px",
        minHeight: "376px",
        maxWidth: "376px",
        maxHeight: "376px",
        verticalAlign: "middle",
        borderRadius: rounded ? "50%" : 0,
        cursor: hasDescription ? "pointer" : "normal",
    }}/>
}

const AvatarWrapper = ({data, top, rounded, onClick}: AvatarWrapperProps) => {
    const {palette} = useTheme();

    return <Grid item flexDirection={"column"} sx={{
        display: "flex",
        margin: {xs: "50px 0 ", xl: "0 50px"},
        alignSelf: {xs: "center", xl: top ? "start" : "end"},
        alignItems: "center",}}
        >
        <AvatarImage avatar={data.avatar} rounded={rounded} hasDescription={data.description != ""} onClick={onClick}/>
        <Typography variant={"h5"} mt={"16px"} textAlign={"center"} color={palette.secondary.main}>{data.title}</Typography>
        <Typography variant={"body1"} mt={"4px"} textAlign={"center"} color={palette.secondary.light} sx={{textTransform: "uppercase", fontWeight: 700}}>{data.subtitle}</Typography>

    </Grid>
}

const AvatarsList = ({content}: Props) => {
    const {palette} = useTheme();
    const [expand, setExpand] = useState(false);
    const [description, setDescription] = useState("");

    const handleClick = (index: number) => {
        if(!expand) {
            setDescription(content.avatars[index].description);
        } else {
            setDescription("");
        }
        setExpand(!expand);
    }

    const renderAvatars = (avatars: any[]) => {
        const elements = []
        if(avatars && avatars.length > 0){
            for(let i = 0; i < avatars.length; i+= 3) {
                elements.push(
                    <>
                        <Grid key={i} item container justifyContent={"center"} flexDirection={{xs: "column", xl: "row"}} sx={{height: {xl: "670px"}, "&:not(:first-of-type)": {mt: {xs:0, xl:"-100px"}}}} wrap={"nowrap"}>
                            {i + 2 < avatars.length ? <AvatarWrapper data={content.avatars[i+2]} rounded={content.rounded_avatars} onClick={() => handleClick(i+2)}/> : <Box sx={{width:"376px",  margin: "0 50px"}}/>}
                            {i < avatars.length ? <AvatarWrapper data={content.avatars[i]} rounded={content.rounded_avatars} onClick={() => handleClick(i)} top/>  : <Box sx={{width:"376px",  margin: "0 50px"}}/>}
                            {i + 1 < avatars.length ? <AvatarWrapper data={content.avatars[i+1]} rounded={content.rounded_avatars} onClick={() => handleClick(i+1)}/>  : <Box sx={{width:"376px",  margin: "0 50px"}}/>}
                        </Grid>
                        <Grid key={"description" + i} item container wrap={"nowrap"}>
                            <Collapse in={expand}>
                                <Typography sx={{color: palette.primary.main}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(description)}}/>
                            </Collapse>
                        </Grid>
                    </>

                )
            }
        }
        return elements;
    }


    return <Grid item container justifyContent={"center"} flexDirection={"column"} alignItems={"center"} sx={{width: "100%", position: "relative", overflow: "hidden"}} pt={"146px"} pb={"92px"}>
        {renderAvatars(content.avatars)}
        <Bubble top={"35%"} left={{xs: "15%", xl:"30%"}}/>
        <Bubble top={"55%"} left={{xs: "80%", xl:"55%"}}/>
        <Bubble top={"75%"} left={{xs: "0%", xl:"15%"}} xl/>
    </Grid>
}

export default AvatarsList;