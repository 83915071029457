import {Checkbox, CheckboxProps, styled} from "@mui/material";
import checkRound from "../assets/images/check-round.svg";


const AgreementIcon = styled('span')(({ theme }) => ({
    borderRadius: 5,
    width: 20,
    height: 20,
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: "white",
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const ErrorIcon = styled(AgreementIcon)(({ theme }) => ({
    border: `1px solid red`,
}))

const CheckedIcon = styled(AgreementIcon)(({theme}) => ({
    backgroundColor: 'white',
    color: theme.palette.secondary.main,
    '&:before': {
        display: 'block',
        width: 20,
        height: 20,
        fill: theme.palette.secondary.main,
        backgroundImage: `url(${checkRound})`,
        backgroundSize: "25px 25px",
        backgroundPosition: "center",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: `${theme.palette.secondary.main}55`,
        color: "white",
    },
}));

type Props = {
    checkboxProps: CheckboxProps;
    isError: boolean;
}

const CustomCheckbox = ({checkboxProps, isError}: Props) => {
    return <Checkbox {...checkboxProps} icon={isError ? <AgreementIcon/> : <ErrorIcon/>} checkedIcon={<CheckedIcon/>}/>

}

export default CustomCheckbox;