import BlankLayout from "../../layout/BlankLayout";
import {
    Dialog,
    Fab,
    Grid,
    IconButton,
    InputAdornment,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import Logo from "../../ui/Logo";
import {GreenButton} from "../../ui/GreenButton";
import {useContext, useEffect, useMemo, useState} from "react";
import AuthContext from "../../store/auth-context";
import {useNavigate} from "react-router-dom";
import {getAllAccounts} from "../../api/content-api";
import Bubble from "../../ui/Bubble";
import darkBubble from "../../assets/images/medium-dark-half-right-bubble.png";
import smallLightBubble from "../../assets/images/micro-light-bubble.png";
import largeLightBubble from "../../assets/images/medium-light-bottom-half-bubble.png";
import SearchIcon from "@mui/icons-material/Search";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import EditIcon from "../../ui/icons/EditIcon";
import TrashIcon from "../../ui/icons/TrashIcon";
import AddIcon from '@mui/icons-material/Add';
import UserDialog from "./UserDialog";
import {deleteUser} from "../../api/user-api";
import SnackbarContext from "../../store/snackbar-context";


const SearchField = styled(TextField)(({theme}) => ({
    borderRadius: "25px",
    border: "none",
    backgroundColor: theme.palette.grey[100],
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: "none",
        },
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    border: 0,
    backgroundColor: theme.palette.grey[100],
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.grey[300],
    },
}));

const AccountsListPage = () => {
    const FULLNAME_ASC = "fullNameAscending";
    const FULLNAME_DSC = "fullNameDescending";
    const DATE_ASC = "dateAscending";
    const DATE_DSC = "dateDescending";
    const {token, isAdmin, logout} = useContext(AuthContext);
    const [users, setUsers] = useState<any[]>([]);
    const [filteredUsers, setFilteredUsers] = useState(users);
    const [searchInput, setSearchInput] = useState("");
    const [sort, setSort] = useState<string | null>(null);
    const [page, setPage] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [openConfirmationDialog, setConfirmationOpenDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(-1);
    const [editUser, setEditUser] = useState(null);
    const {setMsg} = useContext(SnackbarContext);
    const {palette} = useTheme();
    const navigate = useNavigate();
    const rowsPerPage = 10;


    useEffect(() => {
        if(token && isAdmin) {
            getAllAccounts(token, () => logout())
                .then((data: any) => {
                    setUsers(data.data);
                    setFilteredUsers(data.data.slice(0, rowsPerPage));
                })
        } else {
            logout();
            navigate("/login");
        }
    }, [])

    const filterBySearchInput = (users: any, searchInput: string ) => {
        if(searchInput != "" && users.length > 0) {
            const search = searchInput.toLowerCase();
            return users.filter((u: any) => { return u.fullName.toLowerCase().includes(search) ||
                u.login.toLowerCase().includes(search) ||
                u.email.toLowerCase().includes(search) ||
                u.account.toLowerCase().includes(search) ||
                (u.updated && u.updated.toLowerCase().includes(search))})
        }
        return users;
    }

    const filteredBySearchInput = useMemo(() => filterBySearchInput(users, searchInput),[users, searchInput])

    const sortUsers = (users: any[], sort: string | null): any[] => {
        switch (sort) {
            case FULLNAME_DSC: {
                return users.sort((a, b) => {
                    if(a.fullName.toLowerCase() > b.fullName.toLowerCase()) {
                     return -1;
                    }
                    if(a.fullName.toLowerCase() < b.fullName.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                })
            }
            case FULLNAME_ASC: {
                return users.sort((a, b) => {
                    if(b.fullName.toLowerCase() > a.fullName.toLowerCase()) {
                        return -1;
                    }
                    if(b.fullName.toLowerCase() < a.fullName.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                })
            }
            case DATE_ASC: {
                return users.sort((a, b) => {
                    if(a.updated === null) {
                        return 1
                    }
                    if(b.updated === null) {
                        return -1
                    }
                    if(a.updated.toLowerCase() > b.updated.toLowerCase()) {
                        return -1;
                    }
                    if(a.updated.toLowerCase() < b.updated.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                })
            }
            case DATE_DSC: {
                return users.sort((a, b) => {
                    if(a.updated === null) {
                        return -1
                    }
                    if(b.updated === null) {
                        return 1
                    }
                    if(b.updated.toLowerCase() > a.updated.toLowerCase()) {
                        return -1;
                    }
                    if(b.updated.toLowerCase() < a.updated.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                })
            }
            default: {
                return users;
            }
        }
    }

    useEffect(() => {
        const sortedList = sortUsers(filteredBySearchInput, sort);
        const filteredList = sortedList.slice(rowsPerPage * page, (rowsPerPage * page) + rowsPerPage )
        setFilteredUsers(filteredList);
    }, [page, filteredBySearchInput, sort])

    const handleLogout = () => {
        logout();
        navigate('/');
    }

    const setSortByDate = () => {
        if(sort != DATE_DSC){
            setSort(DATE_DSC)
        } else {
            setSort(DATE_ASC)
        }
    }

    const setSortByFullname = () => {
        if(sort != FULLNAME_DSC){
            setSort(FULLNAME_DSC)
        } else {
            setSort(FULLNAME_ASC)
        }
    }

    const handleDelete = () => {
        if(token && isAdmin) {
            deleteUser(deleteId, token, () => {
                logout();
                navigate("/login");
                })
                .then((response) => {
                    setMsg({msg: "Użytkownik został usunięty"});
                    window.location.reload();
                })
                .catch((error) => setMsg({msg: "Wystąpił błąd. Spróbuj ponownie później."}))
        } else {
            logout();
            navigate("/login");
        }
    }

    const getUpdateDttm = (updateDttm: string) => {
        console.log(updateDttm)
        if(updateDttm && updateDttm !== '') {
            const date = new Date(updateDttm);
            return date.toLocaleDateString();
        }
        return '---'
    }

    return <BlankLayout>
        <Grid container sx={{width: "100%", position: "relative", overflow: "hidden"}} flexDirection={"column"} alignItems={"center"} flexWrap={"nowrap"}>
            <Grid item container justifyContent={"space-between"} sx={{width: {lg: "1175px", xs: "90%"}, mt: {xs: "24px", md: "48px"}, zIndex: 2}}>
                <Logo sx={{height: {xs: "50px", md: "100%"}}}/>
                <Grid item alignItems={"center"} sx={{display: "flex", justifyContent: "end"}}>
                    <Typography variant={"body2"} sx={{fontWeight: 700, mr: {xs: "20px", md: "70px"}}}>administrator</Typography>
                    <GreenButton onClick={handleLogout} sx={{p:{xs: "6px 12px", md: "15px 48px"}, fontSize: {xs: "16px", md: "18px"}}}>Wyloguj się</GreenButton>
                </Grid>
            </Grid>
            <Grid item container sx={{width: {lg: "1175px", xs: "90%"}, height: "100%", mt: "156px", mb: "345px", zIndex: 10}} alignItems={"center"}>
                <Grid item container alignItems={"start"} justifyContent={"end"}>
                    <SearchField placeholder={"Szukaj"} size={"small"} InputProps={{
                        endAdornment: <InputAdornment position="end"><SearchIcon/></InputAdornment>,
                    }} onChange={(event) => {setPage(0);setSearchInput(event.target.value)}}/>
                </Grid>
                    <TableContainer sx={{mt: "91px", "& td,th": {border: 0}}}>
                        <Table>
                           <TableHead>
                               <TableRow sx={{border: 0}}>
                                   <TableCell sx={{display: "flex", alignItems: "center", width: "100%"}}>
                                       <Typography variant={"body2"} sx={{fontWeight: 700, color: palette.primary.main}}>Imię i nazwisko dziecka</Typography>
                                       <IconButton onClick={setSortByFullname}>
                                           <UnfoldMoreIcon color={"primary"}/>
                                       </IconButton>
                                   </TableCell>
                                   <TableCell >
                                       <Typography variant={"body2"} sx={{fontWeight: 700, color: palette.primary.main}}>Login</Typography>
                                   </TableCell>
                                   <TableCell>
                                       <Typography variant={"body2"} sx={{fontWeight: 700, color: palette.primary.main}}>E-mail</Typography>
                                   </TableCell>
                                   <TableCell>
                                       <Typography variant={"body2"} sx={{fontWeight: 700, color: palette.primary.main}}>Kwota</Typography>
                                   </TableCell>
                                   <TableCell sx={{display: "flex", alignItems: "center"}}>
                                       <Typography variant={"body2"} sx={{fontWeight: 700, color: palette.primary.main}}>Data aktualizacji</Typography>
                                       <IconButton onClick={setSortByDate}>
                                           <UnfoldMoreIcon color={"primary"}/>
                                       </IconButton>
                                   </TableCell>
                                   <TableCell/>
                               </TableRow>
                           </TableHead>
                            <TableBody>
                                {filteredUsers.map((item: any, index) => (
                                    <StyledTableRow key={index}>
                                        <TableCell>
                                            {item.fullName}
                                        </TableCell>
                                        <TableCell>
                                            {item.login}
                                        </TableCell>
                                        <TableCell>
                                            {item.email}
                                        </TableCell>
                                        <TableCell>
                                            {item.account}
                                        </TableCell>
                                        <TableCell>
                                            {getUpdateDttm(item["updated"])}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => {setEditUser(item); setOpenDialog(true)}}>
                                                <EditIcon/>
                                            </IconButton>
                                            <IconButton onClick={() => {setConfirmationOpenDialog(true); setDeleteId(item.id)}}>
                                                <TrashIcon/>
                                            </IconButton>
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                <Grid item container justifyContent={"space-between"}>
                    <TablePagination
                        rowsPerPageOptions={[rowsPerPage]}
                        component="div"
                        count={searchInput != "" ? filteredUsers.length : users.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                    />
                    <Fab color="secondary" sx={{mt: "40px"}} onClick={() => setOpenDialog(true)}>
                        <AddIcon />
                    </Fab>
                </Grid>
            </Grid>
            <Bubble src={darkBubble} top={"30%"} left={"calc(100% - 200px)"}/>
            <Bubble src={smallLightBubble} top={"calc(100% - 250px)"} left={"25%"}/>
            <Bubble src={largeLightBubble} top={"calc(100% - 130px)"} left={"10%"}/>
            {openDialog && <UserDialog user={editUser} open={openDialog} onClose={() => {setOpenDialog(false); setEditUser(null)}} />}
            {openConfirmationDialog && <Dialog open={openConfirmationDialog} onClose={() => setConfirmationOpenDialog(false)}>
                <Grid container alignItems={"center"} flexDirection={"column"} sx={{p:"48px"}}>
                    <Typography variant={"h6"} mb={"24px"} color={palette.secondary.main} sx={{fontWeight: 700}}>
                        {"Czy jesteś pewny, że chcesz usunąć użytkownika?"}
                    </Typography>
                    <GreenButton onClick={handleDelete}>Usuń</GreenButton>
                </Grid>
            </Dialog>}
        </Grid>
    </BlankLayout>
}

export default AccountsListPage;