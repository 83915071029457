import {Dialog, DialogContent, DialogTitle, Grid, Typography, useTheme} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import PaymentContext from "./payment-context";
import {GreenButton} from "../../ui/GreenButton";
import {PayPalButtons, usePayPalScriptReducer} from "@paypal/react-paypal-js";
import SnackbarContext from "../../store/snackbar-context";

type PaymentConfirmationProps = {
    onBack: () => void;
}

const PaymentConfirmation = ({onBack}: PaymentConfirmationProps) => {
    const {firstName, lastName, email, amount, type} = useContext(PaymentContext);
    const {setMsg} = useContext(SnackbarContext);
    const {palette} = useTheme();
    const [openPaypal, setOpenPaypal] = useState(false);
    const [{ options}, dispatch] = usePayPalScriptReducer();

    const isSubscription = (type === 'regular');

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                vault: type === 'regular',
                intent: (type === 'regular') ? "subscription" : "capture"
            }
        })
    }, [type])

    const applicationContext = {
        shipping_preference: "NO_SHIPPING",
        brand_name: "Fundacja Pomocy Dzieciom z chorobami Nowotworowymi",
        user_action: isSubscription ? "SUBSCRIBE_NOW" : "PAY_NOW"
    }

    const handleGoNext = () => {
        setOpenPaypal(true);
    }

    const handleSubscription = (data: any, actions: any) => {
        const now = new Date();
        now.setHours(now.getHours() + 1);
        return actions.subscription.create({
            "plan_id": process.env.REACT_APP_PAYPAL_SUB_PLAN_ID ? process.env.REACT_APP_PAYPAL_SUB_PLAN_ID : "",
            "start_time": now.toISOString(),
            "quantity": `${amount}`,
            "subscriber": {
                "name": {
                    "given_name": firstName,
                    "surname": lastName
                },
                "email_address": email,
            },
            "application_context": applicationContext
        });
    }

    const handleCreatePayment = (data: any, actions: any) => {
        return actions.order.create({
            application_context: applicationContext,
            purchase_units: [
                {
                    description: "Wsparcie Fundacji Pomocy Dzieciom z chorobami Nowotworowymi",
                    amount: {
                        value: `${amount}`
                    },
                }
            ]
        })
    }

    return <Grid item container alignItems={"center"} flexDirection={"column"} mt={"50px"} wrap={"nowrap"}>
            <Grid item container flexDirection={"column"} alignItems={"center"} sx={{backgroundColor: palette.secondary.main, width: {xs: "90%", md: "528px"}, borderRadius: "5px"}}>
                <Typography variant={"h5"} color={"white"} mt={"48px"} sx={{fontWeight: 700}}>Wielkość wpłaty: {amount}zł</Typography>
                <Grid item container p={"32px 64px 0 64px"}>
                    <Typography variant={"body2"} color={"white"} sx={{fontSize: "17px", fontWeight: 400, width: {xs: "50%",md:"40%"}}}>Imię nazwisko</Typography>
                    <Typography variant={"body2"} color={"white"} sx={{fontSize: "18px", fontWeight: 700}}>{firstName} {lastName}</Typography>
                </Grid>
                <Grid item container p={"16px 64px 0 64px"}>
                    <Typography variant={"body2"} color={"white"} sx={{fontSize: "17px", fontWeight: 400, width: {xs: "50%",md:"40%"}}}>Adres e-mail</Typography>
                    <Typography variant={"body2"} color={"white"} sx={{fontSize: "18px", fontWeight: 700}}>{email}</Typography>
                </Grid>
                <Grid item container p={"16px 64px 48px 64px"}>
                    <Typography variant={"body2"} color={"white"} sx={{fontSize: "17px", fontWeight: 400, width: {xs: "50%",md:"40%"}}}>Metoda płatności</Typography>
                    <Typography variant={"body2"} color={"white"} sx={{fontSize: "18px", fontWeight: 700}}>PayPal</Typography>
                </Grid>
            </Grid>
        <Grid item container sx={{p: {xs:"48px 0px", md: "96px 100px 136px 100px"}, width: {xs: "90%", md: "100%"}}} justifyContent={{xs:"center", md: "end"}} flexDirection={{xs: "column", md: "row"}} wrap={"nowrap"}>
            <GreenButton onClick={() => onBack()} sx={{
                mr: {xs: 0, md: "48px"},
                mb: {xs: "24px", md: 0},
                backgroundColor: "white",
                color: palette.secondary.main}}>Wróć</GreenButton>
            <GreenButton onClick={() => handleGoNext()}>Potwierdzam i przechodzę do płatności</GreenButton>
            <Dialog fullWidth onClose={()=> setOpenPaypal(false)} open={openPaypal}>
                <DialogTitle>Wybierz płatność</DialogTitle>
                <DialogContent>
                        <PayPalButtons
                            style={{height: 50}}
                            createSubscription={isSubscription ? (data, actions) => handleSubscription(data, actions) : undefined}
                            createOrder={isSubscription ? undefined : (data, actions) => handleCreatePayment(data, actions)}
                            onApprove={async (data, actions) => {
                                const order = await actions.order?.capture();
                                setOpenPaypal(false);
                                setMsg({msg: "Dziękujemy za wsparcie Fundacji!", severity: "info"  })
                            }}
                            onError={(err) => {
                                setMsg({msg: "Płatność nie powiodła się. Spróbuj ponownie później", severity: "error" })
                            }}
                            onCancel={() => setOpenPaypal(false)}
                        />
                </DialogContent>
            </Dialog>
        </Grid>
    </Grid>
}

export default PaymentConfirmation;