import {Button, styled, useMediaQuery, useTheme} from "@mui/material";
import FullHeader from "./FullHeader";
import MinHeader from "./MinHeader";
import {NavLink} from "react-router-dom";

const StyledButton = styled(Button)(({theme}) => ({
    height: "50px",
    borderRadius: "100px",
    border: "2px solid",
    fontWeight: 700,
    textTransform: "none",
    whiteSpace: "nowrap",
    '& a': {
        textTransform: "none",
        textDecoration: "none",
        color: 'inherit',
        [theme.breakpoints.up('xl')]: {
            padding: "20px 40px",
        },
        [theme.breakpoints.down('xl')]: {
            padding: "10px 20px",
        },
    }
}))

export const PayOnlineButton = () => {
    return <StyledButton color={"secondary"}>
        <NavLink to={'/dzialaj-z-nami/jak-pomoc'}>Wpłać online</NavLink>
    </StyledButton>;
}

const Header = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));

    return <>
        {matches ? <FullHeader /> : <MinHeader/>}
    </>
}

export default Header;
