import {Grid, IconButton, Typography, useTheme} from "@mui/material";
import DownloadIcon from "./icons/DownloadIcon";

type Props = {
    title: string;
    url: string;
    subtype: string;
    dark?: boolean;
}

const DownloadTile = ({title, url, subtype, dark}: Props) => {
    const {palette} = useTheme();

    return <Grid item container alignItems={"center"} sx={{backgroundColor: dark ? palette.grey[200] : palette.grey[100], height: "92px"}}>
        <Grid item container justifyContent={"center"} alignItems={"center"} sx={{backgroundColor: palette.primary.main, borderRadius: "50%", ml: "24px", width: "44px", height: "44px"}}>
            <Typography sx={{fontFamily: "Exo", fontSize: "17px", color: "white", textAlign: "center"}}>{subtype && subtype.toUpperCase()}</Typography>
        </Grid>
        <Typography sx={{fontWeight: 700, ml: "24px"}} color={palette.primary.main}>{title}</Typography>
        <IconButton sx={{m: "auto", mr: {xs: "24px", md: "118px"}}} href={url} target={"_blank"}>
            <DownloadIcon/>
        </IconButton>
    </Grid>
}

export default DownloadTile;