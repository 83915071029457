import {Box, Grid, Button, Typography, useTheme, styled} from "@mui/material";
import {useContext} from "react";
import ContentContext from "../store/content-context";
import NumberOneCircle from "../ui/icons/NumberOneCircle";
import NumberTwoCircle from "../ui/icons/NumberTwoCircle";
import NumberThreeCircle from "../ui/icons/NumberThreeCircle";
import Line from "../ui/icons/Line";
import BubbleLeft from '../assets/images/bubble-left.png';
import BubbleRight from '../assets/images/bubble-right.png';
import Heart from '../ui/icons/Heart';

type Props = {
    content: any;
}

const GridStyledItem = styled(Grid) ({
    display: 'grid',
    justifyItems: 'center',
    alignContent: 'start'
})

const TypographyStyled = styled(Typography) ({
    textAlign: 'center'
})

const FoundationDetails = ({content}: Props) => {
    const {palette} = useTheme();
    const {foundation} = useContext(ContentContext);

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" xs={12}
              sx={{
                  backgroundColor: palette.secondary.main,
                  position: 'relative'
              }}>
            <Box sx={{
                position: 'absolute',
                top: {xs: '-35px', lg:'-35px'},
                right: {xs: '50%', sm: '5%', lg: '8%'},
                transform: {xs: 'translateX(50%)', sm: 'translateY(0)'},
                zIndex: 2,
                width: {xs: '100%', sm: 'auto'},
                textAlign: 'center'
            }}>
                {content.button && <Button href={content.button[0].link.url} variant="contained" color="secondary" endIcon={<Heart />} sx={{backgroundColor: palette.secondary.light, textTransform: 'none', fontWeight: 'bold', boxShadow: 'none', fontSize: '18px', color: 'white', borderRadius: '50px', padding: {xs: '20px 30px', sm: '20px 50px'}}}>{content.button[0].button}</Button>}
            </Box>
            <Box component='img' sx={{
                position: 'absolute',
                top: {sm: '-90px', md: '-130px'},
                left: {sm: '-160px', lg: '-120px', xl: '-40px'},
                zIndex: 2,
                display: {xs: 'none', sm: 'block'}
            }}
                 alt='Fundacja Pomocy Dzieciom'
                 src={BubbleLeft}
            />
            <Box component='img' sx={{
                position: 'absolute',
                top: {xs: '600px', lg: '250px'},
                right: {xs: 0, md: 0},
                zIndex: 2,
                display: {xs: 'none', sm: 'block'}
            }}
                 alt='Fundacja Pomocy Dzieciom'
                 src={BubbleRight}
            />
            <Grid item>
                <Typography variant={'h5'} color={'white'} pt={'70px'} pb={4}>{content.title}</Typography>
            </Grid>
            <Grid container item justifyContent="center" alignItems="space-between" sx={{zIndex: 3}}>
                <GridStyledItem item xs={10} lg={3}>
                    <NumberOneCircle />
                    <TypographyStyled sx={{fontWeight: 'bold'}} color={'white'} pt={{xs: 3, lg: 3}}>{content.subtitle_first}</TypographyStyled>
                    <TypographyStyled color={'white'} pt={2}>{foundation.account}</TypographyStyled>
                    <TypographyStyled color={'white'}>{foundation.bank}</TypographyStyled>
                    <TypographyStyled sx={{width: '70%'}} color={palette.secondary.light} pt={2}>{content.description}</TypographyStyled>
                </GridStyledItem>
                <Box sx={{display: {xs: 'none', lg: 'block'}}}>
                    <Line />
                </Box>
                <GridStyledItem item xs={10} lg={3}>
                    <Box pt={{xs: 3, lg: 0}}>
                        <NumberTwoCircle />
                    </Box>
                    <TypographyStyled sx={{fontWeight: 'bold'}} color={'white'} pt={{xs: 3, lg: 2.5}}>{content.subtitle_second}</TypographyStyled>
                    <TypographyStyled color={'white'} pt={2}>KRS: {foundation.krs}</TypographyStyled>
                    <TypographyStyled color={'white'}>NIP: {foundation.nip}</TypographyStyled>
                </GridStyledItem>
                <Box sx={{display: {xs: 'none', lg: 'block'}}}>
                    <Line />
                </Box>
                <GridStyledItem item xs={10} lg={3}>
                    <Box pt={{xs: 3, lg: 0}}>
                        <NumberThreeCircle />
                    </Box>
                    <TypographyStyled sx={{fontWeight: 'bold'}} color={'white'} pb={{xs: 3, lg: 4}} pt={{xs: 3, lg: 2}} pl={1}>{content.subtitle_third}</TypographyStyled>
                    {content.button_download && <Button href={content.button_download[0].link} sx={{'&:hover': {backgroundColor: `${!content.button_download[0].button ? 'transparent' : ''}`}, color: palette.secondary.light, border: `${content.button_download[0].button ? `2px solid ${palette.secondary.light}` : ''}`, textTransform: 'none', borderRadius: '32px', padding: '10px 30px'}}>{content.button_download[0].button ? content.button_download[0].button : <Box component={'img'} sx={{maxWidth: '180px'}} src={content.button_download[0].graphic.url}></Box>}</Button>}
                </GridStyledItem>
            </Grid>
            <Grid item pt={6} pb={5} sx={{zIndex: 3}}>
                {content.button_help && <Button href={content.button_help[0].link.url} variant="contained" color="secondary" sx={{':hover': {color: 'white', backgroundColor: `${!content.button_help[0].button ? 'transparent' : ''}`}, backgroundColor: 'white', color: palette.secondary.main, textTransform: 'none', fontWeight: 'bold', boxShadow: 'none', borderRadius: '32px', padding: content.button_help[0].button ? '14px 40px' : '0px'}}>{content.button_help[0].button ? content.button_help[0].button : <Box component={'img'} sx={{maxWidth: '180px'}} src={content.button_help[0].graphic.url}></Box>}</Button>}
            </Grid>
        </Grid>
    )
}

export default FoundationDetails;
